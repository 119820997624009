import React from "react";
import { Container, Row, Col } from "reactstrap";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {
  ColorlibConnector,
  ColorlibConnectorOMO,
  ColorlibStepIcon,
  ColorlibStepIconOMO,
  useStyles,
  useStylesOMO,
} from "./FlujoHistorialMaterialUIStyles";

import styles from "./FlujoHistorial.module.css";

export default ({ state, history, regresarAlInicio }) => {
  const { tipoConsulta } = state;
  let tipoCita =
    tipoConsulta === "presencial"
      ? "Agendamos tu Cita Presencial"
      : "Agendemos una cita con tu Oftalmólogo desde casa";
  const classes = tipoConsulta === "presencial" ? useStyles() : useStylesOMO();
  if (state.flujo1.length !== 0 && history !== "/cita/tipoConsulta") {
    const { flujo1, flujoStep } = state;
    return (
      <Container fluid className={`${styles.flujoHistorialContainer}`}>
        <Container>
          <Row className={`${styles.rowFlujoHistorial}`}>
            <Col
              md={{ size: 12 }}
              lg={{ size: 12 }}
              className={`${styles.colFlujoHistorialTitle} d-flex justify-content-center align-items-end`}
            >
              <p className={`${styles.colFlujoHistorialTitle__title}`}>
                {tipoCita}
              </p>
            </Col>
            <Col
              md={{ size: 12 }}
              lg={{ size: 12 }}
              className={`${styles.colFlujoDePaciente} d-flex justify-content-center align-items-end`}
            >
              <Stepper
                alternativeLabel
                activeStep={flujoStep}
                connector={
                  tipoConsulta === "presencial" ? (
                    <ColorlibConnector />
                  ) : (
                    <ColorlibConnectorOMO />
                  )
                }
                className={classes.root}
              >
                {flujo1.map((label) => (
                  <Step key={label}>
                    <StepLabel
                      StepIconComponent={
                        tipoConsulta === "presencial"
                          ? ColorlibStepIcon
                          : ColorlibStepIconOMO
                      }
                    >
                      <p
                        className={
                          tipoConsulta === "presencial"
                            ? styles.stepperLabel
                            : styles.stepperLabelOMO
                        }
                      >
                        {label}
                      </p>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
  // else if (history === "/cita/tipoConsulta") {
  //   return (
  //     <Container fluid className={`${styles.containerHeaderTipoDeCita}`}>
  //       <Row className={`${styles.rowContainerHeaderTipoDeCita}`}>
  //         <Col
  //           xs={12}
  //           className={`${styles.colContainerButtonHeaderTipoDeCita} d-flex justify-content-end align-items-end`}
  //         >
  //           <button
  //             onClick={() => regresarAlInicio()}
  //             className={`${styles.colContainerButtonHeaderTipoDeCita__button}`}
  //           >
  //             <Container fluid>
  //               <Row>
  //                 <Col xs={8} className={`${styles.colContainerNombreBoton}`}>
  //                   Salir
  //                 </Col>
  //                 <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
  //                   <span
  //                     className={`${styles.colContainerButtonHeaderTipoDeCita__span}`}
  //                   >
  //                     <svg
  //                       className={`${styles.colContainerButtonHeaderTipoDeCita__svg}`}
  //                     >
  //                       <use xlinkHref={`${spriteArrow}#icon-arrow-right`} />
  //                     </svg>
  //                   </span>
  //                 </Col>
  //               </Row>
  //             </Container>
  //           </button>
  //         </Col>
  //       </Row>
  //     </Container>
  //   );
  // }
  else {
    return null;
  }
};
