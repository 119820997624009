import React, { Component } from "react";
import { Container, Col, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import BotonGeneral from "components/BotonGeneral";
import BotonGeneralRedondo from "components/BotonGeneral/BotonGeneralRedondo";
import RoundInputText from "components/textInput";
import HeaderServicios from "./HeaderServicios";

import { existePromocion, existePromocionConfigAliados, vigentePromocion, vigentePromocionConfigAliados} from "functions/Promociones";
import { existeCampania, vigenteCampania } from "functions/Campanias";

import DiagnosticosData from "data/Diagnosticos.json";
import promociones from "data/promociones.json";
import FlowsData from "data/flows.json";
import ConfigAliados from "data/ConfigAliados.json";

import Estetoscopio from "../images/svg/diagnosticos__estetoscopio.svg";

import styles from "../css/Style.module.css";

let indexDiagnostic;
let age;
let pasosParaFlujo1 = [];
let tipoConsultaHistorialFlujo = "presencial";

const headerName = "Diagnósticos";
let TXT_TOAST = "";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    top: 0,
    right: "-16px",
    color: "#10798b",
    ["@media (max-width:750px)"]: {
      top: 0,
      right: "-20px",
    },
  },
  icon: {
    border: "none",
    borderRadius: 50,
    width: 16,
    height: 16,
  },
  checkedIcon: {
    backgroundColor: "#ffcd00",
    "&:before": {
      borderColor: "#ffcd00",
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#ffcd00",
    },
  },
  checked: {
    "&+$label": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "110px",
      height: "110px",
      backgroundColor: "#fff",
      textAlign: "center",
      borderRadius: "50%",
      border: "1px solid #ffcd00",
      ["@media (max-width:750px)"]: {
        width: "100px",
        height: "100px",
      },
    },
  },
  label: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "110px",
    height: "110px",
    backgroundColor: "#fff",
    textAlign: "center",
    borderRadius: "50%",
    marginLeft: "11px",
    marginRight: "-16px",
    marginBottom: "-10px",
    ["@media (max-width:750px)"]: {
      width: "100px",
      height: "100px",
    },
  },
});

function StyledCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
}
function FormControlLabelStyled(props) {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={`${styles.formControlLabel}`}
      classes={{
        label: classes.label,
      }}
      checked={props.checked}
      control={
        <StyledCheckbox
          classes={{
            checked: classes.checked,
          }}
          onChange={props.onChange}
          value="checkedA"
        />
      }
      label={props.label}
    />
  );
}

// const urlParams = getParams();

class Diagnosticos extends Component {
  constructor(props) {
    super(props);
    const { urlParams, gdlvTipoConsulta } = this.props.history.location.state;
    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[Diagnosticos] History`,
        value: this.props.history.location.state,
      });
    }

    // if (ruta !== "catarataTesting") {
    // this.props.history.push("/trabajando");
    // } else {
    if (urlParams.ruta === "OMO") {
      pasosParaFlujo1 = [
        "Indica tus síntomas",
        "Medio de contacto",
        "Fecha y hora",
        "Datos de contacto",
        "Confirmación de cita",
      ];
      tipoConsultaHistorialFlujo = "remoto";
      this.navegaPorServicio("OMO");
    }

    if (urlParams.medio === "GDLV") {
      pasosParaFlujo1 = [
        "Selecciona tu clínica",
        "Elige la fecha y hora",
        "Confirma tus datos de contacto",
        "Confirmación de cita",
      ];
      this.navegaPorServicio("Guardianes de la vista");
    }

    if (urlParams.medio === "GDLV-P") {
      pasosParaFlujo1 = [
        "Selecciona tu clínica",
        "Elige la fecha y hora",
        "Confirma tus datos de contacto",
        "Confirmación de cita",
      ];
      this.navegaPorServicio(gdlvTipoConsulta);
    }

    if (urlParams.medio === "SHCP") {
      pasosParaFlujo1 = [
        "Selecciona tu clínica",
        "Elige la fecha y hora",
        "Confirma tus datos de contacto",
        "Confirmación de cita",
      ];
      this.navegaPorServicio(gdlvTipoConsulta);
    }

    if (urlParams.medio === "GDLV-Optometria") {
      pasosParaFlujo1 = [
        "Selecciona tu clínica",
        "Elige la fecha y hora",
        "Confirma tus datos de contacto",
        "Confirmación de cita",
      ];
      this.navegaPorServicio(gdlvTipoConsulta);
    }

    if (urlParams.medio === "GeneralCampanas") {
      pasosParaFlujo1 = [
        "Selecciona tu clínica",
        "Elige la fecha y hora",
        "Confirma tus datos de contacto",
        "Confirmacón de cita",
      ];
      this.navegaPorServicio(gdlvTipoConsulta);
    }

    if (urlParams.medio === "Azucar") {
      pasosParaFlujo1 = [
        "Selecciona tu clínica",
        "Elige la fecha y hora",
        "Confirma tus datos de contacto",
        "Confirmación de cita",
      ];
      this.navegaPorServicio(gdlvTipoConsulta);
    }

    if (urlParams.medio === "Metro") {
      pasosParaFlujo1 = [
        "Selecciona tu clínica",
        "Elige la fecha y hora",
        "Confirma tus datos de contacto",
        "Confirmación de cita",
      ];
      this.navegaPorServicio(gdlvTipoConsulta);
    }

    //COMBAK
    if (urlParams.cmp === "PROSPERIA-01") {
      pasosParaFlujo1 = [
        "Selecciona tu clínica",
        "Elige la fecha y hora",
        "Confirma tus datos de contacto",
        "Confirmación de cita",
      ];
      this.navegaPorServicio("PROSPERIA");
    }

    if(urlParams.medio === "CLIVI") {
      pasosParaFlujo1 = [
        "Selecciona tu clínica",
        "Elige la fecha y hora",
        "Confirma tus datos de contacto",
        "Confirmación de cita",
      ];
      this.navegaPorServicio(gdlvTipoConsulta);
    }

    if(urlParams.medio === "N-SOYMAS") {
      pasosParaFlujo1 = [
        "Selecciona tu clínica",
        "Elige la fecha y hora",
        "Confirma tus datos de contacto",
        "Confirmación de cita",
      ];
      this.navegaPorServicio(gdlvTipoConsulta);
    }

    if(urlParams.medio === "JNH") {
      pasosParaFlujo1 = [
        "Selecciona tu clínica",
        "Elige la fecha y hora",
        "Confirma tus datos de contacto",
        "Confirmación de cita",
      ];
      this.navegaPorServicio(gdlvTipoConsulta);
    }

    // }

    this.state = {
      selectedHasDiagnostic: false,
      activeButtonsDiagnostics: false,
      toast: false,
      selectedDiagnostics: Object.keys(Diagnosticos).map(() => false),
      edad: "",
      activeButtonContinue: false,
      errorEdad: "",
      urlParams,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    let { state } = this.props.history.location;
    const { urlParams } = state;

    // Si campaña existe y "NO" pertenece a campañas válidas en arreglo cambia el param cmp a vacío y redirige al origen borrando el parámetro de la url (cmp)
    if (urlParams.cmp !== null) {
      if (existeCampania(urlParams.cmp)) {
        if (!vigenteCampania(urlParams.cmp)) {
          TXT_TOAST =
            "Lo sentimos. Esta promoción ya no está disponible. Puedes continuar agendando tu cita a precio regular.";

          this.setState({ toast: true });
        }
      } else {
        TXT_TOAST =
          "Lo sentimos. Esta promoción no existe. Puedes continuar agendando tu cita a precio regular.";

        this.setState({ toast: true });
      }
    }

    if (ConfigAliados[urlParams.medio] !== undefined){
      console.log("si existe medio en config aliados");
      // Si promoción existe y "NO" pertenece a campañas válidas en arreglo cambia el param cmp a vacío y redirige al origen borrando el parámetro de la url (cmp)
    if (urlParams.promo !== null) {
      let showToast = false;
      let now = moment();

      if (existePromocionConfigAliados(urlParams.promo)) {
        if (!vigentePromocionConfigAliados(urlParams.promo, now)) {
          showToast = true;
          TXT_TOAST =
            "Lo sentimos. Esta promoción ya no está disponible. Puedes continuar agendando tu cita a precio regular.";
        } else {
          this.navegaPorServicio("Promoción");
        }
      } else {
        showToast = true;
        TXT_TOAST =
          "Lo sentimos. Esta promoción no existe. Puedes continuar agendando tu cita a precio regular.";
      }

      if (showToast) {
        this.setState({ toast: true });
      }
    }
    }
    else{
      console.log("no existe medio en config aliados");
      // Si promoción existe y "NO" pertenece a campañas válidas en arreglo cambia el param cmp a vacío y redirige al origen borrando el parámetro de la url (cmp)
    if (urlParams.promo !== null) {
      let showToast = false;
      let now = moment();

      if (existePromocion(urlParams.promo)) {
        if (!vigentePromocion(urlParams.promo, now)) {
          showToast = true;
          TXT_TOAST =
            "Lo sentimos. Esta promoción ya no está disponible. Puedes continuar agendando tu cita a precio regular.";
        } else {
          this.navegaPorServicio("Promoción");
        }
      } else {
        showToast = true;
        TXT_TOAST =
          "Lo sentimos. Esta promoción no existe. Puedes continuar agendando tu cita a precio regular.";
      }

      if (showToast) {
        this.setState({ toast: true });
      }
    }
    }

    // Si promoción existe y "NO" pertenece a campañas válidas en arreglo cambia el param cmp a vacío y redirige al origen borrando el parámetro de la url (cmp)
    /*if (urlParams.promo !== null) {
      let showToast = false;
      let now = moment();

      if (existePromocion(urlParams.promo)) {
        if (!vigentePromocion(urlParams.promo, now)) {
          showToast = true;
          TXT_TOAST =
            "Lo sentimos. Esta promoción ya no está disponible. Puedes continuar agendando tu cita a precio regular.";
        } else {
          this.navegaPorServicio("Promoción");
        }
      } else {
        showToast = true;
        TXT_TOAST =
          "Lo sentimos. Esta promoción no existe. Puedes continuar agendando tu cita a precio regular.";
      }

      if (showToast) {
        this.setState({ toast: true });
      }
    }*/
  }

  navega = () => {
    const { history } = this.props;
    const { urlParams } = history.location.state;

    const header = [
      {
        id: 1,
        titulo: "Diagnósticos:",
        dato: headerName,
        ruta: "/cita/diagnosticos",
        tarjetaID: DiagnosticosData[indexDiagnostic].nombreServicio,
        diagnostico: DiagnosticosData[indexDiagnostic].descripcion,
      },
    ];

    let urlParamsFiltered = { ...urlParams };
    if (urlParams.promo !== null && history.location.state.sinPromocion) {
      urlParamsFiltered.promo = null;
    }

    let newHistory = {
      ...history.location.state,
      header,
      regresion: false,
      prioridad: DiagnosticosData[indexDiagnostic].prioridad,
      para: { paraQuien: 1 },
      diagnostico: { ...DiagnosticosData[indexDiagnostic] },
      urlParams: urlParamsFiltered,
      promocion: null,
      historialFlujo: {
        flujoIsActive: false,
        flujoStep: 0,
        flujo1: pasosParaFlujo1,
        tipoConsulta: tipoConsultaHistorialFlujo,
      },
      EdadPaciente: age,
    };

    newHistory.diagnostico.routes = {
      ...FlowsData[DiagnosticosData[indexDiagnostic].routes],
    };

    history.push(
      newHistory.diagnostico.routes[this.props.location.pathname] +
        history.location.search,
      newHistory
    );
  };

  changeAge = (inputAge) => {
    age = inputAge;
    this.checkValidAge(inputAge);
  };

  checkValidAge = (inputAge) => {
    if (inputAge < 121 && inputAge.length > 0) {
      this.setState({
        errorEdad: "",
        edad: inputAge,
        activeButtonsDiagnostics: true,
      });
    } else {
      this.setState({
        errorEdad: "Edad inválida",
        edad: null,
        activeButtonsDiagnostics: false,
        selectedHasDiagnostic: false,
      });
    }
  };

  getObjectByService = (nameService) => {
    return Object.keys(DiagnosticosData).find(
      (key) => DiagnosticosData[key].nombreServicio === nameService
    );
  };

  navegaPorServicio = (servicio) => {
    let diagnosticoIndex = this.getObjectByService(servicio);
    this.setHistoryForNavigation(diagnosticoIndex);
    this.navega();
  };

  optionDiagnostics = (botonSeleccionado) => {
    const { urlParams } = this.props.history.location.state;
    if (age < 8) {
      this.navegaPorServicio("No disponible");
    } else {
      if (botonSeleccionado === "Si") {
        this.setState({ selectedHasDiagnostic: true });
        if (age < 40 && urlParams.medio !== "Campanas") {
          pasosParaFlujo1 = [
            "Indica tus síntomas",
            "Medio de contacto",
            "Fecha y hora",
            "Datos de contacto",
            "Confirmación de cita",
          ];
          tipoConsultaHistorialFlujo = "remoto";
          this.navegaPorServicio("OMO");
        }
      } else {
        this.setState({ selectedHasDiagnostic: false });
        if (age <= 40) {
          pasosParaFlujo1 = [
            "Indica tus síntomas",
            "Medio de contacto",
            "Fecha y hora",
            "Datos de contacto",
            "Confirmación de cita",
          ];
          tipoConsultaHistorialFlujo = "remoto";
          this.navegaPorServicio("OMO");
        } else {
          this.navegaPorServicio("Consulta Adulto Otros");
        }
        this.navega();
      }
    }
  };

  toggleDiagnostic = (idDiagnostic) => {
    let { selectedDiagnostics } = this.state;
    if (process.env.NODE_ENV !== "production") {
      console.tron.log(`[Diagnosticos] @toggleDiagnostic ${idDiagnostic}`);
    }
    selectedDiagnostics[idDiagnostic] = !selectedDiagnostics[idDiagnostic];
    let activeButtonContinue = selectedDiagnostics.includes(true);
    this.setState({ selectedDiagnostics, activeButtonContinue });
  };

  defineServiceByCheckbox = () => {
    let { selectedDiagnostics } = this.state;
    let arraySelected = selectedDiagnostics.map((diagnostic, index) => {
      if (diagnostic === true) {
        return index + 1;
      }
      return null;
    });
    arraySelected = arraySelected.filter((seleccionado) => {
      return seleccionado;
    });
    if (arraySelected.length > 1) {
      //!Si hay dos o mas diagnosticos seleccionados se envia a consulta adulto otros
      this.setHistoryForNavigation(11);
    } else if (arraySelected.length === 1) {
      //! Si solo se selecciona un solo diagnostico se manda al diagnostico seleccionado
      let indexDiagnostico = arraySelected[0];
      this.setHistoryForNavigation(indexDiagnostico);
    }
    this.navega();
  };

  setHistoryForNavigation = (index) => {
    indexDiagnostic = index;
  };

  render() {
    const { toast, edad, selectedDiagnostics, errorEdad } = this.state;
    return (
      <Container fluid className={`${styles.containerDiagnosticos}`}>
        <HeaderServicios toastText={TXT_TOAST} toastShow={toast} />
        <Row className={`${styles.rowContainer}`}>
          <Col md={12}>
            <Row className="px-5 pb-2 justify-content-center">
              <Col
                lg={12}
                className={`${styles.colContainerEdad} d-flex justify-content-center text-center`}
              >
                <span
                  className={`${styles.font_regular} ${styles.font_size_32} ${styles.color_azul_fuerte} text-center`}
                >
                  Para darte un servicio personalizado, te pedimos proporcionar
                  la siguiente información:
                </span>
              </Col>
              <Col
                lg={12}
                className={`${styles.colContainerEdad} d-flex justify-content-center text-center`}
              >
                <span
                  className={`${styles.font_bold} ${styles.font_size_32} ${styles.color_azul_fuerte} text-center`}
                >
                  ¿Cuál es la edad del paciente?
                </span>
              </Col>
              <Col
                lg={12}
                className={`${styles.colContainerInputEdad} d-flex justify-content-center text-center`}
              >
                <div>
                  <RoundInputText
                    placeholder="Edad"
                    etiqueta="Edad"
                    value={edad}
                    id="Edad"
                    errorText={errorEdad}
                    onChange={(input) => this.changeAge(input)}
                  />
                </div>
              </Col>
            </Row>
            {this.state.edad !== "" && (
              <Row>
                <Col lg={12} className={` d-flex justify-content-center`}>
                  <Row
                    className={`${styles.rowContainerCuadro} d-flex align-items-center justify-content-center`}
                  >
                    <Col
                      lg={12}
                      className={`${styles.colContainerIcon} d-flex justify-content-center align-items-center`}
                    >
                      <img src={Estetoscopio} alt="Estetoscopio" />
                    </Col>{" "}
                    <Col
                      lg={12}
                      className={`${styles.colcontainerTitle} d-flex justify-content-center align-items-center`}
                    >
                      <span
                        className={`${styles.font_bold} ${styles.font_size_32} ${styles.color_azul_fuerte} text-center px-md-3 pt-2`}
                      >
                        ¿Cuenta con algún diagnóstico oftalmológico?
                      </span>
                    </Col>
                    <Col lg={12} className={`d-flex justify-content-center`}>
                      <Row>
                        <Col xs={6}>
                          <BotonGeneralRedondo
                            key={"boton si"}
                            manejarClick={() => this.optionDiagnostics("Si")}
                            nombreBoton="Sí"
                            activo={this.state.activeButtonsDiagnostics}
                            existe={true}
                          />
                        </Col>
                        <Col xs={6}>
                          <BotonGeneralRedondo
                            key={"boton no"}
                            manejarClick={() => this.optionDiagnostics("No")}
                            nombreBoton="No"
                            activo={this.state.activeButtonsDiagnostics}
                            existe={true}
                          />
                        </Col>
                      </Row>
                    </Col>
                    {this.state.selectedHasDiagnostic && (
                      <Col>
                        <Row
                          className={`${styles.rowContainerDiagnosticos} px-4 pb-3`}
                        >
                          <Col
                            lg={12}
                            className={`${styles.colContainerTitleDiagnosticos}`}
                          >
                            <p
                              className={`${styles.colContainerTitleDiagnosticos__p} text-center px-3 pt-2`}
                            >
                              Selecciona el diagnóstico oftalmológico:
                            </p>
                          </Col>
                          <Col
                            lg={12}
                            className={`d-flex justify-content-center`}
                          >
                            <Row
                              className={`${styles.rowContainerFormControlLabel} d-flex justify-content-center`}
                            >
                              {Object.values(DiagnosticosData)
                                .filter((diagnostico) => diagnostico.muestra)
                                .map((diagnostico) => (
                                  <Col
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    lg={3}
                                    xl={3}
                                    className={`${styles.colContainerOneForm}`}
                                  >
                                    <FormControlLabelStyled
                                      label={
                                        <p
                                          className={
                                            styles.formControlLabelDescription__p
                                          }
                                        >
                                          {diagnostico.descripcionSeparada !==
                                            undefined &&
                                            diagnostico.descripcionSeparada.map(
                                              (parrafo) => (
                                                <p
                                                  className={`${styles.formControlLabelDescription__lineaParrafo}`}
                                                >
                                                  {parrafo}
                                                </p>
                                              )
                                            )}
                                          {/* {diagnostico.descripcion} */}
                                        </p>
                                      }
                                      checked={
                                        selectedDiagnostics[
                                          diagnostico.id - 1
                                        ] || false
                                      }
                                      onChange={() =>
                                        this.toggleDiagnostic(
                                          diagnostico.id - 1
                                        )
                                      }
                                    />
                                  </Col>
                                ))}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        {this.state.edad !== "" && (
          <Row lg={12} className={`${styles.rowContainerContinuar}`}>
            <Col lg={12} className={`d-flex justify-content-center`}>
              <BotonGeneral
                manejarClick={this.defineServiceByCheckbox}
                nombreBoton="CONTINUAR"
                activo={this.state.activeButtonContinue}
                existe={this.state.selectedHasDiagnostic}
              />
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default withRouter(Diagnosticos);
