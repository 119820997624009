import React, { useCallback, useEffect, useState } from "react";
import styles from "./PDFRecuperacion.module.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  // Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Container, Row, Col } from "reactstrap";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



        export const PDFRecuperacion = ({
          dataSignosVitales,
          nota,
          medicamentos,
          isLogin,
          sVFreciemcoaCardiaca,
          sVSpO2,
          sVTemperatura,
          sVGlucosa,
          sVFrecuenciaRespiratoria,
          sVTensionArterial
        }) => {
      useEffect(() => {
        if (isLogin == false) {

        setTimeout(() => {
                window.print();
        }, 850);
        console.log('sVFreciemcoaCardiaca: ',sVFreciemcoaCardiaca);
        console.log('sVSpO2: ',sVSpO2);
        console.log('sVTemperatura: ',sVTemperatura);
        console.log('sVGlucosa: ',sVGlucosa);
        console.log('sVFrecuenciaRespiratoria: ',sVFrecuenciaRespiratoria);
        console.log('sVTensionArterial: ',sVTensionArterial);

        }
      }, [isLogin]);

      const medicamentosMap = medicamentos.map((medicamento) => (
        <li>{medicamento}</li>
      ));



  const optionsFC = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
    scales: {
      y: {
        // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
        suggestedMin: 0,

        // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
        suggestedMax: 120,
      }
    }

  };

  const optionsSpO2 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
    scales: {
      y: {
        // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
        suggestedMin: 0,

        // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
        suggestedMax: 120,
      }
    }
  };

  const optionsTemperatura = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
    scales: {
      y: {
        // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
        suggestedMin: 0,

        // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
        suggestedMax: 50,
      }
    }
  };

  const optionsTensionArterial = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
    scales: {
      y1: {
        position: "right",
        // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
        suggestedMin: 0,

        // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
        suggestedMax: 200,
      },
      y2: {
        position: "left",
        // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
        suggestedMin: 0,

        // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
        suggestedMax: 200,
      }
    }
  };
  const optionsFrecuenciaRespiratoria = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
    scales: {
      y: {
        // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
        suggestedMin: 0,

        // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
        suggestedMax: 40,
      }
    }
  };

  const optionsGlucosa = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
    scales: {
      y: {
        // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
        suggestedMin: 50,

        // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
        suggestedMax: 400,
      }
    }
  };
  
  const labelsFC = sVFreciemcoaCardiaca.map((val) => val.fechaYHora );
  const labelsSpO2 = sVSpO2.map((val) => val.fechaYHora );
  const labelsTemperatura = sVFreciemcoaCardiaca.map((val) => val.fechaYHora );
  const labelsTensionArterial = sVSpO2.map((val) => val.fechaYHora );
  const labelsFrecuenciaRespiratoria = sVFreciemcoaCardiaca.map((val) => val.fechaYHora );
  const labelsGlucosa = sVSpO2.map((val) => val.fechaYHora );

  
  
  const dataFC = {
    labels: labelsFC,
    datasets: [
      {
        label: 'Frecuencia cardiaca',
        data: sVFreciemcoaCardiaca.map((val) => val.value ),
        borderColor: 'rgb(0, 201, 44)',
        backgroundColor: 'rgb(0, 201, 44)',
      },
     
    ],
  };
  
    
  const dataSpO2 = {
    labels: labelsSpO2,
    datasets: [
      {
        label: 'Saturacion',
        data: sVSpO2.map((val) => val.value ),
        borderColor: 'rgb(124, 129, 196)',
        backgroundColor: 'rgb(124, 129, 196)',
      },
     
    ],
  };
  const dataTemperatura = {
    labels: labelsTemperatura,
    datasets: [
      {
        label: 'Temperatura',
        data: sVTemperatura.map((val) => val.value ),
        borderColor: 'rgb(64, 196, 185)',
        backgroundColor: 'rgb(64, 196, 185)',
      },
     
    ],
  };
  
    
  const dataTensionArterial = {
    labels: labelsTensionArterial,
    datasets: [
      {
        label: 'Presión sistólica',
        data: sVTensionArterial.map((val) => val.value1 ),
        borderColor: 'rgb(27, 0, 71)',
        backgroundColor: 'rgb(27, 0, 71)',
      },
      {
        label: 'Presión diastólica',
        data: sVTensionArterial.map((val) => val.value2 ),
        borderColor: 'rgb(201, 0, 184)',
        backgroundColor: 'rgb(201, 0, 184)',
      },
     
    ],
  };
  const dataFrecuenciaRespiratoria = {
    labels: labelsFrecuenciaRespiratoria,
    datasets: [
      {
        label: 'Frecuencia respiratoria',
        data: sVFrecuenciaRespiratoria.map((val) => val.value ),
        borderColor: 'rgb(100, 59, 175)',
        backgroundColor: 'rgb(100, 59, 175)',
      },
     
    ],
  };
  
    
  const dataGlucosa = {
    labels: labelsGlucosa,
    datasets: [
      {
        label: 'Glucosa',
        data: sVGlucosa.map((val) => val.value ),
        borderColor: 'rgb(216, 36, 105)',
        backgroundColor: 'rgb(216, 36, 105)',
      },
     
    ],
  };  


  
  // if (dataSignosVitales.length > 0) {
    return (
      <Container fluid className={styles.containerSignosVitales}>
        {/* <div className={styles.esquinaDerecha} >
          <span  style={{ color: "black", fontSize: "20px"  }}>Página 1</span>
        </div> */}
      <Row className={styles.rowContainerGraph}> 

        <div className={styles.mainContainer} style={{ fontSize: "18px" }}>
          <table width="100%" className={styles.headerPrint}  style={{ fontSize: "18px" }}>
            <tbody>
              <tr>
                <td width="20%">
                  <img
                    src="https://salauno--c.na82.content.force.com/servlet/servlet.ImageServer?id=0151R0000065756&oid=00D36000000HFeS&lastMod=1665527866000"
                    width="180px"
                  />
                </td>
                <td width="80%" className={styles.cabecera}  style={{ fontSize: "18px" }}>
                  <table>
                    <tbody style={{ fontSize: "18px"}}>
                      <tr>
                        <td width="410px" >
                          <strong>Nombre: </strong>
                          <span>{nota.paciente_Nombre}</span>
                          {/* <span>Maria Del carmen estela Benitez Peña </span> */}
                        </td>
                        <td width="180px">
                          <strong>Género: </strong>
                          <span>{nota.paciente_Sexo}</span>
                        </td>
                        <td width="210px">
                          <strong>Expediente: </strong>
                          <span>{nota.account_Number}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Fecha de nacimiento: </strong>
                          <span>{nota.paciente_Cumple}</span>
                        </td>
                        <td>
                          <strong>Edad: </strong>
                          <span>{nota.paciente_Edad} años</span>
                        </td>
                        <td>
                          <strong>Cirugía: </strong>
                          <span>{nota.cirugia_Nombre} </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <div className={`${styles.titulo} ${styles.pt30} ${styles.fS30}`}  style={{ fontSize: "30px" }}> 
            <strong>
              HOJA DE ENFERMERÍA
              <br />
              Recuperación
            </strong>
          </div>

          <div className={styles.contenido}  style={{ fontSize: "20px" }}>
            <strong style={{ fontSize: "20px" }}> Fecha y hora: </strong>
            <span style={{ color: "black", fontSize: "20px" , fontSize: "20px" }}>{nota.fechaDeCreacion} hrs.</span>
            <br />
            <br />
            <table align="center" width="100%">
              <tbody  style={{ fontSize: "20px" }}>
                <tr>
                  <td width="50%" style={{ textAlign: "left" }}>
                    <strong style={{ fontSize: "20px" }}>Datos antropométricos</strong>
                  </td>
                  <td width="50%" style={{ textAlign: "left" }}>
                    <strong style={{ fontSize: "20px" }}>Signos vitales:</strong>
                  </td>
                </tr>

                <tr>
                  <td
                    width="50%"
                    style={{ textAlign: "left", verticalAlign: "top" }}
                  >
                    Peso:
                    <span style={{ color: "black", fontSize: "20px"  }}>
                      {nota.hisMed_Peso} Kg
                    </span>
                    <br />
                    Talla:
                    <span style={{ color: "black", fontSize: "20px"  }}>
                      {nota.histMed_Talla} m
                    </span>
                    <br />
                    IMC:
                    <span style={{ color: "black", fontSize: "20px"  }}>{nota.hisMed_IMC}</span>
                    <br />
                  </td>
                  <td
                    width="50%"
                    style={{ textAlign: "left", verticalAlign: "top" }}
                  >
                    Tensión arterial:
                    <span style={{ color: "black", fontSize: "20px"  }}>
                      {nota.tensionArterial} mmHg
                    </span>
                    <br />
                    Glucosa:
                    <span style={{ color: "black", fontSize: "20px"  }}>{nota.glucosa} mg/dL</span>
                    <br />
                    Saturación de oxígeno periférico (SpO2):
                    <span style={{ color: "black", fontSize: "20px"  }}>{nota.sPO2} %</span>
                    <br />
                    Frecuencia cardiaca:
                    <span style={{ color: "black", fontSize: "20px"  }}>
                      {nota.frecuenciaCardiaca} LPM
                    </span>
                    <br />
                    Temperatura:
                    <span style={{ color: "black", fontSize: "20px"  }}>{nota.tempratura} °C</span>
                    <br />
                    Frecuencia respiratoria:
                    <span style={{ color: "black", fontSize: "20px"  }}>
                      {nota.frecuenciaRespiratoria} RPM
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table align="center" width="100%">
              <tbody  style={{ fontSize: "20px" }}>
                <tr>
                  <td
                    width="50%"
                    style={{ textAlign: "left", verticalAlign: "top" }}
                  >
                    <strong style={{ fontSize: "20px" }}>Ministración de medicamentos</strong>
                    <br />
                    {medicamentos.length > 0 ? (
                      <ul>{medicamentosMap}</ul>
                    ) : (
                      <p>Sin medicamentos</p>
                    )}

                    <table align="center" width="100%">
                      <tr>
                        <td width="50%" style={{ textAlign: "left", verticalAlign: "top" }}>
                          <strong style={{ fontSize: "20px" }}>Valoración de riesgo de caída</strong>
                        </td>
                        <td width="50%" style={{ textAlign: "left", verticalAlign: "top" }}>
                          <strong style={{ fontSize: "20px" }}>Valoracion del dolor</strong>
                        </td>
                      </tr>
                      <tr>
                        <td width="50%" style={{ textAlign: "left", verticalAlign: "top" }}>
                          Caídas previas: <span style={{ color: "black", fontSize: "20px"  }}>{nota.cirugia_CaidasPrevias}</span><br />
                          Aplicación de tranquilizantes, sedantes: <span style={{ color: "black", fontSize: "20px"  }}>{nota.cirugia_ApliTranquilizantes}</span><br />
                          Déficits sensoriales: <span style={{ color: "black", fontSize: "20px"  }}>{nota.cirugia_DeficitsSensoriales}</span><br />
                          Deambulación: <span style={{ color: "black", fontSize: "20px"  }}>{nota.cirugia_Deambulacion}</span><br />
                          Estado mental: <span style={{ color: "black", fontSize: "20px"  }}>{nota.cirugia_EstadoMental}</span><br />
                          <strong>Nivel de riesgo de caída: </strong><span style={{ color: "black", fontSize: "20px"  }}>{nota.cirugia_RiesgiCaida}</span><br />
                        </td>
                        <td width="50%" style={{ textAlign: "left", verticalAlign: "top" }}>
                          Escala de dolor: <span style={{ color: "black", fontSize: "20px"  }}>{nota.cirugia_EscalaDolor}</span>
                        </td>
                      </tr>

                    </table>
                  </td>
                  {/* <td
                    width="50%"
                    style={{ textAlign: "left", verticalAlign: "top" }}
                  >
                    <strong style={{ fontSize: "20px" }}>Habitus Exterior</strong>
                    <br />
                    Deambulación:
                    <span style={{ color: "black", fontSize: "20px"  }}>//NO EXISTE//</span>
                    <br />
                    Integridad:
                    <span style={{ color: "black", fontSize: "20px"  }}>//NO EXISTE//</span>
                    <br />
                    Constitución
                    <span style={{ color: "black", fontSize: "20px"  }}>//NO EXISTE//</span>
                    <br />
                    <br />
                    <br />
                    Estado de ánimo:
                    <span style={{ color: "black", fontSize: "20px"  }}>//NO EXISTE//</span>
                    <br />
                    Estado de conciencia:
                    <span style={{ color: "black", fontSize: "20px"  }}>//NO EXISTE//</span>
                    <br />
                  </td> */}
                </tr>
              </tbody>
            </table>
            <br />
            <strong style={{ fontSize: "20px" }}>Signos vitales</strong>
            <br />
            <table
              className={`${styles.tabla} ${styles.bordesVerdes} ${styles.bordesVerdes}`}
            >
              <tbody  style={{ fontSize: "20px" }}>
                <tr>
                  <td colSpan="8" className={`${styles.fondoVerdeObscuro}`}></td>
                  <td colSpan="8" className={`${styles.fondoVerdeObscuro}`}>
                    <strong style={{ fontSize: "20px" }}>Tensión Arterial</strong>
                  </td>
                  <td colSpan="8" className={`${styles.fondoVerdeObscuro}`}>
                    <strong style={{ fontSize: "20px" }}>Frecuencia Cardiaca</strong>
                  </td>
                  <td colSpan="8" className={`${styles.fondoVerdeObscuro}`}>
                    <strong style={{ fontSize: "20px" }}>Frecuencia Respiratoria</strong>
                  </td>
                  <td colSpan="8" className={`${styles.fondoVerdeObscuro}`}>
                    <strong style={{ fontSize: "20px" }}>Saturación oxígeno periférico</strong>
                  </td>
                  <td colSpan="8" className={`${styles.fondoVerdeObscuro}`}>
                    <strong style={{ fontSize: "20px" }}>Glucosa</strong>
                  </td>
                  <td colSpan="8" className={`${styles.fondoVerdeObscuro}`}>
                    <strong style={{ fontSize: "20px" }}>Temperatura</strong>
                  </td>
                </tr>

                { !!dataSignosVitales[0] ? (
                <tr>
                  <td colSpan="8">{dataSignosVitales[0].etapaDeRegistro}</td> 
                  <td colSpan="8">{dataSignosVitales[0].tensionArterial} mmHg</td>
                  <td colSpan="8">{dataSignosVitales[0].frecuenciaCardiaca} LPM</td>
                  <td colSpan="8">{dataSignosVitales[0].frecuenciaRespiratoria} RPM</td>
                  <td colSpan="8">{dataSignosVitales[0].spO2} %</td>
                  <td colSpan="8">{dataSignosVitales[0].glucosa} mg/dL</td>
                  <td colSpan="8">{dataSignosVitales[0].tempratura} °C</td>
                </tr>
               ): null }

               { !!dataSignosVitales[1] ? (
                <tr>
                  <td colSpan="8">{dataSignosVitales[1].etapaDeRegistro}</td> 
                  <td colSpan="8">{dataSignosVitales[1].tensionArterial} mmHg</td>
                  <td colSpan="8">{dataSignosVitales[1].frecuenciaCardiaca} LPM</td>
                  <td colSpan="8">{dataSignosVitales[1].frecuenciaRespiratoria} RPM</td>
                  <td colSpan="8">{dataSignosVitales[1].spO2} %</td>
                  <td colSpan="8">{dataSignosVitales[1].glucosa} mg/dL</td>
                  <td colSpan="8">{dataSignosVitales[1].tempratura} °C</td>
                </tr>
               ): null }

               { !!dataSignosVitales[2] ? (
                <tr>
                  <td colSpan="8">{dataSignosVitales[2].etapaDeRegistro}</td> 
                  <td colSpan="8">{dataSignosVitales[2].tensionArterial} mmHg</td>
                  <td colSpan="8">{dataSignosVitales[2].frecuenciaCardiaca} LPM</td>
                  <td colSpan="8">{dataSignosVitales[2].frecuenciaRespiratoria} RPM</td>
                  <td colSpan="8">{dataSignosVitales[2].spO2} %</td>
                  <td colSpan="8">{dataSignosVitales[2].glucosa} mg/dL</td>
                  <td colSpan="8">{dataSignosVitales[2].tempratura} °C</td>
                </tr>
               ): null }

               { !!dataSignosVitales[3]? (
                <tr>
                  <td colSpan="8">{dataSignosVitales[3].etapaDeRegistro}</td> 
                  <td colSpan="8">{dataSignosVitales[3].tensionArterial} mmHg</td>
                  <td colSpan="8">{dataSignosVitales[3].frecuenciaCardiaca} LPM</td>
                  <td colSpan="8">{dataSignosVitales[3].frecuenciaRespiratoria} RPM</td>
                  <td colSpan="8">{dataSignosVitales[3].spO2} %</td>
                  <td colSpan="8">{dataSignosVitales[3].glucosa} mg/dL</td>
                  <td colSpan="8">{dataSignosVitales[3].tempratura} °C</td>
                </tr>
               ): null }

               { !!dataSignosVitales[4] ? (
                <tr>
                  <td colSpan="8">{dataSignosVitales[4].etapaDeRegistro}</td> 
                  <td colSpan="8">{dataSignosVitales[4].tensionArterial} mmHg</td>
                  <td colSpan="8">{dataSignosVitales[4].frecuenciaCardiaca} LPM</td>
                  <td colSpan="8">{dataSignosVitales[4].frecuenciaRespiratoria} RPM</td>
                  <td colSpan="8">{dataSignosVitales[4].spO2} %</td>
                  <td colSpan="8">{dataSignosVitales[4].glucosa} mg/dL</td>
                  <td colSpan="8">{dataSignosVitales[4].tempratura} °C</td>
                </tr>
               ): null }

               { !!dataSignosVitales[5] ? (
                <tr>
                  <td colSpan="8">{dataSignosVitales[5].etapaDeRegistro}</td> 
                  <td colSpan="8">{dataSignosVitales[5].tensionArterial} mmHg</td>
                  <td colSpan="8">{dataSignosVitales[5].frecuenciaCardiaca} LPM</td>
                  <td colSpan="8">{dataSignosVitales[5].frecuenciaRespiratoria} RPM</td>
                  <td colSpan="8">{dataSignosVitales[5].spO2} %</td>
                  <td colSpan="8">{dataSignosVitales[5].glucosa} mg/dL</td>
                  <td colSpan="8">{dataSignosVitales[5].tempratura} °C</td>
                </tr>
               ): null }

               { !!dataSignosVitales[6] ? (
                <tr>
                  <td colSpan="8">{dataSignosVitales[6].etapaDeRegistro}</td> 
                  <td colSpan="8">{dataSignosVitales[6].tensionArterial} mmHg</td>
                  <td colSpan="8">{dataSignosVitales[6].frecuenciaCardiaca} LPM</td>
                  <td colSpan="8">{dataSignosVitales[6].frecuenciaRespiratoria} RPM</td>
                  <td colSpan="8">{dataSignosVitales[6].spO2} %</td>
                  <td colSpan="8">{dataSignosVitales[6].glucosa} mg/dL</td>
                  <td colSpan="8">{dataSignosVitales[6].tempratura} °C</td>
                </tr>
               ): null }

               { !!dataSignosVitales[7] ? (
                <tr>
                  <td colSpan="8">{dataSignosVitales[7].etapaDeRegistro}</td> 
                  <td colSpan="8">{dataSignosVitales[7].tensionArterial} mmHg</td>
                  <td colSpan="8">{dataSignosVitales[7].frecuenciaCardiaca} LPM</td>
                  <td colSpan="8">{dataSignosVitales[7].frecuenciaRespiratoria} RPM</td>
                  <td colSpan="8">{dataSignosVitales[7].spO2} %</td>
                  <td colSpan="8">{dataSignosVitales[7].glucosa} mg/dL</td>
                  <td colSpan="8">{dataSignosVitales[7].tempratura} °C</td>
                </tr>
               ): null }

               { !!dataSignosVitales[8] ? (
                <tr>
                  <td colSpan="8">{dataSignosVitales[8].etapaDeRegistro}</td> 
                  <td colSpan="8">{dataSignosVitales[8].tensionArterial} mmHg</td>
                  <td colSpan="8">{dataSignosVitales[8].frecuenciaCardiaca} LPM</td>
                  <td colSpan="8">{dataSignosVitales[8].frecuenciaRespiratoria} RPM</td>
                  <td colSpan="8">{dataSignosVitales[8].spO2} %</td>
                  <td colSpan="8">{dataSignosVitales[8].glucosa} mg/dL</td>
                  <td colSpan="8">{dataSignosVitales[8].tempratura} °C</td>
                </tr>
               ): null }

               { !!dataSignosVitales[9] ? (
                <tr>
                  <td colSpan="8">{dataSignosVitales[9].etapaDeRegistro}</td> 
                  <td colSpan="8">{dataSignosVitales[9].tensionArterial} mmHg</td>
                  <td colSpan="8">{dataSignosVitales[9].frecuenciaCardiaca} LPM</td>
                  <td colSpan="8">{dataSignosVitales[9].frecuenciaRespiratoria} RPM</td>
                  <td colSpan="8">{dataSignosVitales[9].spO2} %</td>
                  <td colSpan="8">{dataSignosVitales[9].glucosa} mg/dL</td>
                  <td colSpan="8">{dataSignosVitales[9].tempratura} °C</td>
                </tr>
               ): null }
                
              </tbody>
            </table>

            {/* <div  className={`${styles.pt180}`}>
  
                <table align="center" width="80%" className={`${styles.pt50}`}>
                      <tbody  style={{ fontSize: "20px" }}>

                      <tr>
                        
                          <td width="50%">
                          <Line options={optionsTensionArterial} data={dataTensionArterial} />
                          </td> 
        
                          <td width="50%">
                          <Line options={optionsFC} data={dataFC} />
                          </td>  
                        </tr>
                                          
                      </tbody>
                    </table>
                </div> */}
                {/* <div className={styles.esquinaDerecha} >
                  <span  style={{ color: "black", fontSize: "20px"  }}>Página 1</span>
                </div> */}
            <div className={styles.pagebreak}></div>

{/* 
            <div className={styles.esquinaDerecha} >
              <span  style={{ color: "black", fontSize: "20px"  }}>Página 2</span>
            </div> */}

            <div className={`${styles.pt95}`}>

                <table align="center" width="80%" className={`${styles.pt50}`}>
                  <tbody  style={{ fontSize: "20px" }}>
                  <tr>
                    
                      <td width="50%">
                      <Line options={optionsTensionArterial} data={dataTensionArterial} />
                      </td> 
    
                      <td width="50%">
                      <Line options={optionsFC} data={dataFC} /> 
                      </td>  
                    </tr>
                    <tr>
                      <td width="50%">
                      <Line options={optionsFrecuenciaRespiratoria} data={dataFrecuenciaRespiratoria} />
                      </td>                    

                      <td width="50%">
                      <Line options={optionsSpO2} data={dataSpO2} />
                      </td>
                    
                    </tr>
                    <tr>
                      
                      <td width="50%">
                      <Line options={optionsGlucosa} data={dataGlucosa} />
                      </td>
                      <td width="50%">
                      <Line options={optionsTemperatura} data={dataTemperatura} />
                      </td>

                    
                    </tr>

                  
                  </tbody>
                </table>
                <br />

                <div>
                  Nota de enfermería: <span style={{ color: "black", fontSize: "20px"  }}>{nota.cirugia_NotQuirEnfermeria}</span>
                </div>

                <div>
                  Observaciones: <span style={{ color: "black", fontSize: "20px"  }}>Ninguna</span>
                </div>

              <div className={`${styles.firma} ${styles.pt30} `} >
                <div>
                      <spam className={`${styles.valDato}`} >
                            &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                            &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                      </spam>
                      <br />
                      <strong style={{ fontSize: "20px" }}>Nombre y firma de enfermería. </strong><span>{nota.personalName}</span>
                      <br />
                      <strong style={{ fontSize: "20px" }}>Cédula profesional. </strong><span>{nota.cedulaProfesional}</span>
                </div>

              </div>


            </div>


          </div>
          
          {/* <div className={styles.esquinaDerecha2} >
              <span  style={{ color: "black", fontSize: "20px"  }}>Página 2</span>
            </div> */}
          <div className={`${styles.piedepagina}`}>


            
          <p  style={{  margin: "6px" , fontSize: "18px" }}>Salauno Salud SAPI de C.V.</p>
          <p  style={{  margin: "6px" , fontSize: "18px" }}>Hamburgo 115, Col. Juárez, Cuauhtémoc, C.P.06600, Ciudad de México, Tel.(55)41636969 - www.salauno.com.mx</p>
          <p  className={`${styles.margenIzquierdo}`} >Folio de Impresión: {nota.folioImpresion}</p>
          

        </div>
        </div>
      </Row>

        </Container>
    );
  // } else {
  //   return  (
  //     <Container fluid className={styles.containerSignosVitales}>
  //         <Row className={styles.rowContainerGraph}> 
  //             <div>
  //               Esta cirugía no tiene información de signos vitales
  //             </div>
  //         </Row>
  //     </Container>
  //   )
  // }
};