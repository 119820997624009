import React, { useState } from "react";
import { Row } from "reactstrap";
import Calendar from "react-calendar";
import moment from "moment";
import "./FullCalendar.css";

let disabledDates = [];

function findDate(disabledDates, date) {
  let foundDate = false;
  disabledDates.forEach((dDate) => {
    if (
      moment
        .utc(date)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .isSame(
          moment.utc(dDate).hours(0).minutes(0).seconds(0).milliseconds(0)
        )
    ) {
      foundDate = true;
    }
  });
  return foundDate;
}

function tileDisabled({ date, view }) {
  if (view === "month") {
    return findDate(disabledDates, date);
  }
}

function setMissingDates(diaInicial, diaFinal, dias) {
  disabledDates = [];
  for (let dia = diaInicial, diaIndex = 0; moment(dia).isSameOrBefore(diaFinal); dia = new Date(moment(dia).add(1, "d").toString())) {
    let auxDia = moment(dia).toString().substring(0, 15);
    let uxDiaArray = moment(!!dias[diaIndex].date ? dias[diaIndex].date : dias[diaIndex].time).toString().substring(0,15);
    if (auxDia === uxDiaArray) {
      // console.log(`comparacion ${auxDia}-${uxDiaArray}`);
      diaIndex += 1;
    } else {
      // console.log(`comparacion fallida ${auxDia}-${uxDiaArray}`);
      disabledDates.push(auxDia);
    }
  }
}

export default function FullCalendar(props) {
  let diaInicialStr = !!props.dias[0].date? new Date(moment.utc(props.dias[0].date).format(moment.HTML5_FMT.DATE).toString()): new Date(moment.utc(props.dias[0].time).format(moment.HTML5_FMT.DATE).toString());
  let diaFinalStr = !!props.dias[0].date? new Date(moment.utc(props.dias[props.dias.length - 1].date).format(moment.HTML5_FMT.DATE).toString()): new Date(moment.utc(props.dias[props.dias.length - 1].time).format(moment.HTML5_FMT.DATE).toString());

  const [value, onChange] = useState(diaInicialStr);

  setMissingDates(diaInicialStr, diaFinalStr, props.dias);

  let body = (
    <>
      <Row className="justify-content-center">
        <Calendar
          id="calendar"
          onChange={onChange}
          value={value}
          minDate={
            new Date(
              moment
                .utc(diaInicialStr)
                .add(1, "days")
                .format(moment.HTML5_FMT.DATE)
                .toString()
            )
          }
          maxDate={
            new Date(
              moment
                .utc(diaFinalStr)
                .add(1, "days")
                .format(moment.HTML5_FMT.DATE)
                .toString()
            )
          }
          locale="es-MX"
          showFixedNumberOfWeeks
          tileDisabled={tileDisabled}
          onClickDay={(value, event) => {
            onChange(value);
            props.loadHours(value);
          }}
        />
      </Row>
    </>
  );

  return body;
}
