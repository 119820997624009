import React from "react";
import validator from "validator";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import Login from "./Login";
import "../../../firebase/config";
import { useForm } from "Hooks/useForm";
import { startLoginEmailPassword } from "Actions/Auth";

const LoginContainer = ({ history }) => {
  const dispatch = useDispatch();

  const [formValues, handleInputChange] = useForm({
    email: "malfavontalaveraj@gmail.com",
    password: "prueba",
  });

  const { email, password } = formValues;

  const handleLogin = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      console.log("Formulario correcto");
      dispatch(startLoginEmailPassword(email, password));
      console.log(email, password);
    }
  };

  const handleSingUp = () => {
    console.log("press");
    history.push(`/cita/SingUpMetro${history.location.search}`);
  };

  const isFormValid = () => {
    if (!validator.isEmail(email)) {
      console.log("El correo no es valido");
      return false;
    } else if (password.length < 5) {
      console.log("La contraseña debe de tener por lo menos 6 caracteres");
    }
    return true;
  };

  return (
    <Login
      handleLogin={handleLogin}
      formValues={formValues}
      handleInputChange={handleInputChange}
      handleSingUp={handleSingUp}
    />
  );
};

export default withRouter(LoginContainer);
