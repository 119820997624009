import React from "react";
import { Toast, ToastBody, ToastHeader } from "reactstrap";
import styles from "./GeneralToastStyles.module.css";

let backgroundToast;
let toastContainer;
let toast;
let toastHeader;
let toastBody;
export default (props) => {
  const { mensajeError, show, toggle, backgroundHeader, typeStyles } = props;

  if (backgroundHeader === "Yellow") {
    backgroundToast = styles.backgroundToast__Yellow;
  }
  if (typeStyles === "FormaDePago") {
    toastContainer = styles.toastFormaDePagoContainer;
    toast = styles.toastFormaDePago;
    toastHeader = styles.toastFormaDePago__toastHeader;
    toastBody = styles.toastFormaDePago__toastBody;
  }
  return (
    <div
      className={
        show === true ? `${toastContainer} ` : `${styles.disableToastContainer}`
      }
    >
      <Toast isOpen={show} className={`${toast}`}>
        <ToastHeader
          toggle={toggle}
          className={`${toastHeader} ${backgroundToast}`}
        >
          Mensaje de Advertencia
        </ToastHeader>
        <ToastBody className={`${toastBody}`}>
          <p>{mensajeError}</p>
        </ToastBody>
      </Toast>
    </div>
  );
};
