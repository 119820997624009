import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import sprite from 'images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg';

import styles from './CreaLeadStyles.module.css';

export default ({ gdlvCampaign }) => {
  return (
    <Container fluid className={`${styles.containerBienvenido}`}>
      <Container className={`${styles.bienvenidoContainer}`}>
        <Row className={`${styles.rowContainerFormulario}`}>
          <Col className={`${styles.colContainerFormulario}`}>
            <form
              action='https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8'
              method='POST'
              className={`${styles.formContainer}`}>
              <h1 className={`${styles.formContainer__h1}`}>
                Ingresa los datos de tu paciente
              </h1>
              <Container fluid className={`${styles.containerInFormulario}`}>
                <input type='hidden' name='oid' value='00D36000000HFeS' />
                <input
                  type='hidden'
                  name='retURL'
                  value='https://citas.salauno.com.mx/cita/bienvenida?medio=GDLV-P'
                />
                <Row className={`${styles.rowContainerItems}`}>
                  <Col xs={12} md={6} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor='first_name'
                          className={`${styles.colContainerLabel__label}`}>
                          Nombre
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          id='first_name'
                          maxlength='40'
                          name='first_name'
                          size='20'
                          type='text'
                          className={`${styles.colContainerInput__input}`}
                          placeholder='Nombre(s)'
                          required
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor='last_name'
                          className={`${styles.colContainerLabel__label}`}>
                          Apellido
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          id='last_name'
                          maxlength='80'
                          name='last_name'
                          size='20'
                          type='text'
                          className={`${styles.colContainerInput__input}`}
                          placeholder='Apell_P Appell_M'
                          required
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor='email'
                          className={`${styles.colContainerLabel__label}`}>
                          Correo
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          id='email'
                          maxlength='80'
                          name='email'
                          size='20'
                          type='text'
                          className={`${styles.colContainerInput__input}`}
                          placeholder='unCorreo@gmail.com'
                          required
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor='phone'
                          className={`${styles.colContainerLabel__label}`}>
                          Telefono de casa
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          id='phone'
                          maxlength='120'
                          name='phone'
                          size='10'
                          type='tel'
                          className={`${styles.colContainerInput__input}`}
                          placeholder='55xxxxxxxx'
                          required
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor='mobile'
                          className={`${styles.colContainerLabel__label}`}>
                          Celular
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          id='mobile'
                          maxlength='10'
                          name='mobile'
                          size='10'
                          type='tel'
                          className={`${styles.colContainerInput__input}`}
                          placeholder='55xxxxxxxx'
                          required
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} md={6} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor='mobile'
                          className={`${styles.colContainerLabel__label}`}>
                          ¿Cual es el padecimiento?
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <select
                          className={`${styles.rowContainerItem__select}`}
                          id='00N1R00000TWdE6'
                          name='00N1R00000TWdE6'
                          title='GDLV_refiere_por'>
                          <option value='Abrasion corneal'>
                            Abrasion corneal
                          </option>
                          <option value='Cuerpo extraño'>Cuerpo extraño</option>
                          <option value='Absceso corneal'>
                            Absceso corneal
                          </option>
                          <option value='Agujero macular'>
                            Agujero macular
                          </option>
                          <option value='Ambliopia.'>Ambliopia.</option>
                          <option value='Astigmatismo mixto'>
                            Astigmatismo mixto
                          </option>
                          <option value='Astigmatismo hipermetrópico compuesto'>
                            Astigmatismo hipermetrópico compuesto
                          </option>
                          <option value='Astigmatismo hipermetrópico simple'>
                            Astigmatismo hipermetrópico simple
                          </option>
                          <option value='Astigmatismo miópico simple'>
                            Astigmatismo miópico simple
                          </option>
                          <option value='Astigmatismo miópico compuesto'>
                            Astigmatismo miópico compuesto
                          </option>
                          <option value='Catarata'>Catarata</option>
                          <option value='Chalazión'>Chalazión</option>
                          <option value='Conjuntivitis'>Conjuntivitis</option>
                          <option value='Degeneración macular relacionada con la edad'>
                            Degeneración macular relacionada con la edad
                          </option>
                          <option value='Desprendimiento de retina'>
                            Desprendimiento de retina
                          </option>
                          <option value='Edema macular'>Edema macular</option>
                          <option value='Edema palpebral'>
                            Edema palpebral
                          </option>
                          <option value='Hemoragia vitrea'>
                            Hemoragia vitrea
                          </option>
                          <option value='Lesiones en cornea'>
                            Lesiones en cornea
                          </option>
                          <option value='Herida palpebral'>
                            Herida palpebral
                          </option>
                          <option value='Dolor ocular'>Dolor ocular</option>
                          <option value='Hipema'>Hipema</option>
                          <option value='Hipermetrópia'>Hipermetrópia</option>
                          <option value='Miopía'>Miopía</option>
                          <option value='LIO de CA'>LIO de CA</option>
                          <option value='Lesion predisponente'>
                            Lesion predisponente
                          </option>
                          <option value='Orzuelo'>Orzuelo</option>
                          <option value='Ojo seco'>Ojo seco</option>
                          <option value='Oclusión de via lagrimal'>
                            Oclusión de via lagrimal
                          </option>
                          <option value='Ojo unico funcional'>
                            Ojo unico funcional
                          </option>
                          <option value='Otro problema en retina'>
                            Otro problema en retina
                          </option>
                          <option value='Pinguecula'>Pinguecula</option>
                          <option value='Papila sospechosa'>
                            Papila sospechosa
                          </option>
                          <option value='Presbicia'>Presbicia</option>
                          <option value='Pseudofaquia'>Pseudofaquia</option>
                          <option value='Pterigion'>Pterigion</option>
                          <option value='Ptisis bulbi'>Ptisis bulbi</option>
                          <option value='Ptosis'>Ptosis</option>
                          <option value='Queratoconjuntivitis'>
                            Queratoconjuntivitis
                          </option>
                          <option value='Queratocono'>Queratocono</option>
                          <option value='Retinipatia diabetica'>
                            Retinipatia diabetica
                          </option>
                          <option value='Simblefaron'>Simblefaron</option>
                          <option value='Sospecha de glaucoma'>
                            Sospecha de glaucoma
                          </option>
                          <option value='Trauma ocular'>Trauma ocular</option>
                          <option value='Ulcera corneal'>Ulcera corneal</option>
                          <option value='Anisometropia'>Anisometropia</option>
                          <option value='Estrabismo'>Estrabismo</option>
                          <option value='Actualización de lentes'>
                            Actualización de lentes
                          </option>
                          <option value='Otro'>Otro</option>
                        </select>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} md={6} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor='mobile'
                          className={`${styles.colContainerLabel__label}`}>
                          Tipo de consulta a la que refiere
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <select
                          className={`${styles.rowContainerItem__select}`}
                          id='00N1R00000TWdEa'
                          name='00N1R00000TWdEa'
                          title='GDLV_refiere_a'>
                          <option value='Referible a Retina'>
                            Referible a Retina
                          </option>
                          <option value='Referible a Consulta general'>
                            Referible a Consulta general
                          </option>
                          <option value='No referible'>No referible</option>
                        </select>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} md={6} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor='mobile'
                          className={`${styles.colContainerLabel__label}`}>
                          ¿Desea realizar algun comentaro?
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <textarea
                          className={`${styles.rowContainerItem__textArea}`}
                          id='00N1R00000TWdE1'
                          name='00N1R00000TWdE1'
                          rows='10'
                          type='text'
                          wrap='soft'
                        />
                      </Col>
                    </Row>
                  </Col>

                  <input
                    id='00N36000006jZtN'
                    name='00N36000006jZtN'
                    type='hidden'
                    value={gdlvCampaign}
                  />
                  <input
                    id='00N36000005AqLz'
                    name='00N36000005AqLz'
                    title='Medio.'
                    type='hidden'
                    value='GDLV-P'
                  />
                  <input  id="00N36000006jZtD" name="00N36000006jZtD" type="hidden" value="Agenda2.0"/>
                  <Col xs={12} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerButton}`}>
                        <button
                          className={`${styles.colContainerButton__button}`}
                          type='submit'
                          name='submit'>
                          <Container fluid>
                            <Row>
                              <Col
                                xs={7}
                                className={`${styles.colContainerNombreBoton}`}>
                                Enviar
                              </Col>
                              <Col
                                xs={3}
                                className={`${styles.colContainerSpanBoton}`}>
                                <span>
                                  <svg className={`${styles.svg}`}>
                                    <use
                                      xlinkHref={`${sprite}#icon-arrow-right`}
                                    />
                                  </svg>
                                </span>
                              </Col>
                            </Row>
                          </Container>
                        </button>
                        {/* <input type='submit' name='submit' /> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
