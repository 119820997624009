import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import sprite from "../../images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg";
import classNames from "classnames";

import styles from "./BotonGeneral.module.css";

export default class BotonGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = { prevPropsActivo: props.activo, selected: false };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.activo !== state.prevPropsActivo) {
      return {
        prevPropsActivo: props.activo,
      };
    }
    return null;
  }

  getClassNames = () => {
    const { activo, estiloBackground } = this.props;
    const { selected } = this.state;

    return classNames({
      [styles.BotonGeneralActivo]: activo && !selected,
      [styles.background_azul]:
        activo && !selected && estiloBackground === "azul",
      [styles.background_amarillo]: activo && estiloBackground === "",
      [styles.BotonGeneralInactivo]: activo === false,
      [styles.BotonGeneralSeleccionado]: activo && selected,
    });
  };
  manejarClick = () => {
    const { manejarClick } = this.props;
    this.setState({ selected: true });
    manejarClick();
  };

  render() {
    const { existe, nombreBoton, activo, id } = this.props;
    // if (process.env.NODE_ENV !== 'production') { console.tron.log(`[BotonGeneral] activo: ${activo}`) }

    if (existe) {
      return (
        <button
          onClick={activo ? () => this.manejarClick() : null}
          className={this.getClassNames()}
          type="button"
          id={id}
        >
          <Container fluid className={`${styles.buttonContainerParams}`}>
            <Row className={`${styles.rowContainerParams}`}>
              <Col xs={8} className={`${styles.colContainerNombreBoton}`}>
                {nombreBoton}
              </Col>
              <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                <span>
                  <svg className={`${styles.svg}`}>
                    <use xlinkHref={`${sprite}#icon-arrow-right`} />
                  </svg>
                </span>
              </Col>
            </Row>
          </Container>
        </button>
      );
    }

    return null;
  }
}
BotonGeneral.propTypes = {
  existe: PropTypes.bool,
  manejarClick: PropTypes.func,
  nombreBoton: PropTypes.string,
  activo: PropTypes.bool,
  id: PropTypes.string,
  estiloBackground: PropTypes.string,
};

BotonGeneral.defaultProps = {
  existe: false,
  manejarClick: () => {},
  nombreBoton: "",
  activo: false,
  id: "",
  estiloBackground: "",
};
