/* eslint-disable quotes */
import React from "react";
import { Container, Row, Col } from "reactstrap";
import styles from "./LoginStyles.module.css";

const Login = ({
  handleLogin,
  formValues,
  handleInputChange,
  handleSingUp,
}) => {
  const { email, password } = formValues;
  return (
    <Container fluid className={styles.loginContainer}>
      {/* <Row className={styles.rowPrincipalTitle}>
        <h1>Agenda Metro</h1>
        {aux}
      </Row> */}
      <Row className={styles.rowBodyContainer}>
        <Col xs={12} className={styles.colBodyContainer_form}>
          {/* <Row className={styles.rowTitle}>
            <h7>Ingresa tu email y password para continuar</h7>
          </Row> */}
          <Row className={styles.rowForm}>
            <form className={styles.form} onSubmit={handleLogin}>
              <div className={styles.error}>Hola Mundo</div>
              <Container className={styles.formBody}>
                <Row className={styles.formRowItem}>
                  <Col className={styles.formColItem} xs={12}>
                    <label htmlFor="correo">Correco electronico </label>
                  </Col>
                  <Col className={styles.formColItemInput} xs={12}>
                    <input
                      type="text"
                      id="correo"
                      name="email"
                      requeried
                      placeholder="usuario@dominio.com"
                      value={email}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
                <Row className={styles.formRowItem}>
                  <Col className={styles.formColItem} xs={12}>
                    <label htmlFor="contraseña">Contraseña </label>
                  </Col>
                  <Col className={styles.formColItemInput} xs={12}>
                    <input
                      type="password"
                      id="contraseña"
                      name="password"
                      value={password}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
                <Row className={styles.formRowItem}>
                  <Col className={styles.formColItemButton} xs={12}>
                    <button type="submit">Entrar</button>
                  </Col>
                </Row>
              </Container>
              <Container fluid className={styles.createAccountContainer}>
                <Row className={styles.rowCreateAccount}>
                  <Col xs={6} className={styles.colCreateAccount}>
                    <button
                      type="button"
                      className={styles.createAccount}
                      onClick={() => handleSingUp()}
                    >
                      Crear una nueva cuenta
                    </button>
                  </Col>
                  <Col xs={6} className={styles.colCreateAccount}>
                    <button type="button" className={styles.createAccount}>
                      Recuperar contraseña
                    </button>
                  </Col>
                </Row>
              </Container>
            </form>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
