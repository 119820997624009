import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import sprite from "images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg";
import styles from "./CreaPacienteTamizadoStyles.module.css";
import moment from "moment";
import "moment/locale/es";
import { getParams } from "functions/GetUrlParams";

const urlParams = getParams();
let newHistory = {
  historialFlujo: {
    flujoIsActive: false,
    flujoStep: 0,
    flujo1: [],
    tipoConsulta: "",
    //utm_campaing: "",
  },

  urlParams,
  sinPromocion: false,
  header: [],
  regresion: null,
  prioridad: null,
  para: {},
  diagnostico: {},
  promocion: null,
  clinica: {
    cambiarClinica: false,
  },
  reagendar: null,
  cita: {},
  calendarId: null,
  serviceId: null,
  tiempoAntesDeCita: null,
  infoPx: [],
  gdlvTipoConsulta: "",
  edadPromocion: null,
};

function App({ history, history: { location } }) {
  const [showPopup, setShowPopup] = useState(false);
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [responseData, setResponseData] = useState(null);
  // Definir los estados para el nombre y el apellido
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [fecha_nacimiento, setFechaNacimiento] = useState("");
  //
  const [tel_celular, setTelCelular] = useState("");
  const [tel_fijo, setTelFijo] = useState("");
  const [diabetes, setDiabetes] = useState("");

  const [hipertension, setHipertension] = useState("");
  const [otra_enfermedad, setOtraEnfermedad] = useState("");
  const [motivo_cita, setMotivoCita] = useState("");
  const [comentario, setComentario] = useState("");
  const handleEnviar = () => {
    console.log("Se ha hecho clic en ENVIAR TAMIZAJE email: ", email);
    newHistory.gdlvCampaign = history.location.state.gdlvCampaign;
    console.log("Valor de Campáing en Tamizaje: ", newHistory.gdlvCampaign);

    if (
      first_name != "" &&
      last_name != "" &&
      fecha_nacimiento != "" &&
      diabetes != "" &&
      hipertension != "" &&
      otra_enfermedad != "" &&
      motivo_cita != "" &&
      (email != "" || tel_celular != "")
    ) {
      console.log("Se ha ingresado telefono o email");
      setShowPopup(true);
    } else {
      setShowPopup(true);
    }
  };

  // CONSULTA GENERAL

  const comenzarConFlujo = (tipoConsulta) => {
    //console.log("Tipo consulta: ", tipoConsulta);

    const datospaciente = {
      paciente1: first_name,
      apellidoP1: last_name,
      fechaNacim: fecha_nacimiento,
      telefono: tel_celular,
      email: email,
      telefonoCasa: tel_fijo,
      diabetes: diabetes,
      hipertension: hipertension,
      otraenfermedad: otra_enfermedad,
      comentarios_GDLV: comentario,
      motivocita: motivo_cita,
    };

    newHistory.gdlvCampaign = history.location.state.gdlvCampaign;
    console.log("Este es el valor de Campáing en Tamizaje: ",newHistory.gdlvCampaign);
    
    if (newHistory.infoPx.length > 0) {
      console.log('****Dentro de if');
      newHistory.infoPx.shift();
      //newHistory.infoPx = null;
    }
    newHistory.infoPx.push(datospaciente);
    newHistory.gdlvTipoConsulta = tipoConsulta;
    history.push("/cita/diagnosticos" + history.location.search, newHistory);
  };


  /* VISION INTEGRAL
  const comenzarConFlujo = (tipoConsulta) => {
    const datospaciente = {
      paciente1: first_name,
      apellidoP1: last_name,
      fechaNacim: fecha_nacimiento,
      comentarios_GDLV: comentario,
      telefono: tel_celular,
      email: email,
      telefonoCasa: tel_fijo,
    };
    newHistory.infoPx.push(datospaciente);
    newHistory.gdlvTipoConsulta = tipoConsulta;
    history.push("/cita/diagnosticos" + history.location.search, newHistory);
  };*/

  // Función para manejar el envío del formulario
  const handleSubmit = async () => {
    try {
      const response = await fetch(
        "https://salauno-nodeserver.azurewebsites.net/salesforce/creaLeadTamizaje",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded", // Cambiar según sea necesario
            // Aquí puedes agregar más cabeceras si son necesarias
          },
          body: new URLSearchParams({
            action: "creaTamizajeOptometrista",
            nombre: first_name,
            apellidos: last_name,
            fechaNacimiento: fecha_nacimiento,
            correo: email,
            mobile: tel_celular,
            telefono: tel_fijo,
            isDiabetes: diabetes,
            isHipertension: hipertension,
            tieneOtraEnfermerdad: otra_enfermedad,
            motivoCita: motivo_cita,
            comentario: comentario,
            utm_campaign: newHistory.gdlvCampaign,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Error al llamar al endpoint");
      }
      console.log("RESPUESTA EXITOSA");
      const data = await response.json();
      setResponseData(data);
      setShowPopup(false);
      console.log("¿Desea agendar?:", data);
      history.push(
        "/cita/bienvenida?medio=GeneralCampanas&isContactCenter=true"
      ); // Redirigir a la URL deseada
    } catch (error) {
      console.error("Error al realizar la solicitud:", error);
      history.push(
        "/cita/bienvenida?medio=GeneralCampanas&isContactCenter=true"
      );
    }
  };
  const Popup = ({ handleClose, show, data }) => {
    if (!show) return null;
    const handleClosePopup = () => {
      handleClose();
    };
    const handleAccept = () => {
      console.log("Se ha hecho clic en Aceptar");
      setShowSecondPopup(true);
    };
    const handleCGeneral = () => {
      console.log("Se ha hecho clic en CONSULTA GENERAL");
      setShowSecondPopup(false);
    };
    const handleVisionI = () => {
      console.log("Se ha hecho clic en VISION INTEGRAL");
      setShowSecondPopup(false);
    };

    if (showSecondPopup) {
      return (
        <div className={`${styles.modal} ${styles.displayBlock}`}>
          <section className={`${styles.modalMain}`}>
            <div className={styles.modalContent}>
              <p>Elige el servicio</p>
              <button
                onClick={() => comenzarConFlujo("Guardianes de la vista")}
                className={`${styles.consultagButton}`}
              >
                Consulta General
              </button>
              <button
                onClick={() => comenzarConFlujo("Vision Integral GDLV")}
                className={`${styles.colContainerButton__button}`}
              >
                Vision Integral
              </button>
            </div>
          </section>
        </div>
      );
    } else {
      return (
        <div
          id="modalAgendar"
          className={`${styles.modal} ${styles.displayBlock}`}
        >
          <section className={`${styles.modalMain}`}>
            <div className={styles.modalContent}>
              <p>¿Deseas agendar una cita?</p>
              <button
                onClick={handleAccept}
                className={`${styles.acceptButton}`}
              >
                Agendar
              </button>
              <button onClick={handleSubmit} className={styles.cancelButton}>
                Cancelar
              </button>
            </div>
          </section>
        </div>
      );
    }
  };
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  return (
    <Container fluid className={`${styles.containerBienvenido}`}>
      <Container className={`${styles.bienvenidoContainer}`}>
        <Row className={`${styles.rowContainerFormulario}`}>
          <Col className={`${styles.colContainerFormulario}`}>
            <form
              className={`${styles.formContainer}`}
              onSubmit={(e) => e.preventDefault()}
            >
              <h1 className={`${styles.formContainer__h1}`}>
                Ingresa los datos de tu tamizaje{" "}
              </h1>
              <Container fluid className={`${styles.containerInFormulario}`}>
                <input type="hidden" name="oid" value="00D36000000HFeS" />
                <input type="hidden" name="retURL" />
                <Row className={`${styles.rowContainerItems}`}>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="first_name"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Nombre *
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          id="first_name"
                          maxlength="40"
                          name="first_name"
                          size="20"
                          type="text"
                          className={`${styles.colContainerInput__input}`}
                          placeholder="Nombre(s)"
                          required
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="last_name"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Apellidos *
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          id="last_name"
                          maxlength="80"
                          name="last_name"
                          size="20"
                          type="text"
                          className={`${styles.colContainerInput__input}`}
                          placeholder="Apellido P  Appellido M"
                          required
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="email"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Correo electrónico
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          id="email"
                          maxlength="80"
                          name="email"
                          size="20"
                          type="text"
                          className={`${styles.colContainerInput__input}`}
                          placeholder="correo@hotmail.com"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="fecha_nacimiento"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Fecha de nacimiento *
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          type="date"
                          className={`${styles.colContainerInput__input}`}
                          placeholder="dd/mm/aaaa"
                          //onChange={(e) => handleChageNacimiento(e)}
                          onChange={(e) => setFechaNacimiento(e.target.value)}
                          required
                        />
                        <input
                          id="00N36000005wJBE"
                          name="00N36000005wJBE"
                          type="text"
                          className={`${styles.colContainerInput__input} ${styles.displayInput_hidden}`}
                          //value={fecha_nacimiento}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="mobile"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Teléfono celular
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          id="tel_celular"
                          maxlength="10"
                          name="mobile"
                          size="10"
                          type="tel"
                          className={`${styles.colContainerInput__input}`}
                          placeholder="Teléfono celular"
                          onChange={(e) => setTelCelular(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="tel_fijo"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Teléfono fijo
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          id="phone"
                          maxlength="10"
                          name="phone"
                          size="10"
                          type="tel"
                          className={`${styles.colContainerInput__input}`}
                          placeholder="Teléfono fijo"
                          onChange={(e) => setTelFijo(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="diabetes"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Diabetes*
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <select
                          className={`${styles.rowContainerItem__select}`}
                          id="diabetes"
                          name="00N36000006l4O3"
                          title="Diabetes"
                          required
                          onChange={(e) => setDiabetes(e.target.value)}
                        >
                          <option value="">-- Ninguno --</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="hipertension"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Hipertensión*
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <select
                          className={`${styles.rowContainerItem__select}`}
                          id="00N36000006l58Y"
                          name="00N36000006l58Y"
                          title="Hipertensión"
                          required
                          onChange={(e) => setHipertension(e.target.value)}
                        >
                          <option value="">-- Ninguno --</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="otra_enfermedad"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Otra enfermedad Sistemica*
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <select
                          className={`${styles.rowContainerItem__select}`}
                          id="00N3600000OyrbY"
                          name="00N3600000OyrbY"
                          title="Otra enfermedad Sistemica"
                          required
                          onChange={(e) => setOtraEnfermedad(e.target.value)}
                        >
                          <option value="">--Ninguno--</option>
                          <option value="LUPUS">LUPUS</option>
                          <option value="ARTITIS">ARTITIS</option>
                          <option value="ENFERMEDAD DE TIROIDES">
                            ENFERMEDAD DE TIROIDES
                          </option>
                          <option value="ENFERMEDAD DE ADISON">
                            ENFERMEDAD DE ADISON
                          </option>
                          <option value="SÍNDROME SJOGREN">
                            SÍNDROME SJOGREN
                          </option>
                          <option value="VIH">VIH</option>
                          <option value="TUBERCULOSIS">TUBERCULOSIS</option>
                          <option value="ESCLEROSIS MÚLTIPLE">
                            ESCLEROSIS MÚLTIPLE
                          </option>
                          <option value="LEUCEMIA">LEUCEMIA</option>
                          <option value="OTRO">OTRO</option>
                          <option value="SIN PATOLOGÍA APARENTE (SANO)">
                            SIN PATOLOGÍA (SANO)
                          </option>
                          required
                        </select>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <label
                          htmlFor="comentario"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          ¿Desea realizar algun comentario?
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <textarea
                          className={`${styles.rowContainerItem__textArea}`}
                          id="00N1R00000TWdE1"
                          name="00N1R00000TWdE1"
                          rows="10"
                          type="text"
                          wrap="soft"
                          onChange={(e) => setComentario(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="motivo_cita"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Motivo de la cita*
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <select
                          className={`${styles.rowContainerItem__select}`}
                          id="00N3600000OyrbY"
                          name="00N3600000OyrbY"
                          title="Motivo de la cita"
                          required
                          onChange={(e) => setMotivoCita(e.target.value)}
                        >
                          <option value="">--Ninguno--</option>
                          <option value="Me quiero operar - presupuesto">
                            Me quiero operar - presupuesto
                          </option>
                          <option value="Catarata / Retina / Mácula / Glaucoma">
                            Catarata/Retina/Mácula/Glaucoma
                          </option>
                          <option value="Empeoró visión de repente">
                            Empeoró visión de repente
                          </option>
                          <option value="Tengo dolor en el ojo">
                            Tengo dolor en el ojo
                          </option>
                          <option value="Necesito segunda opinión">
                            Necesito segunda opinión
                          </option>
                          <option value="Renovar Lentes">Renovar Lentes</option>
                          <option value="Ojo rojo / Infección">
                            Ojo rojo/Infección
                          </option>
                          <option value="Vista cansada">Vista Cansada</option>
                          <option value="Me quiero operar de Catarata">
                            Me quiero operar de Catarata
                          </option>
                          <option value="Me quiero operar de Retina, Mácula o Glaucoma">
                            Me quiero operar de Retina/Mácula/Glaucoma
                          </option>
                          <option value="Otro">OTRO</option>
                        </select>
                      </Col>
                    </Row>
                  </Col>

                  <input
                    id="00N36000006jZtN" //Codigo Unico
                    name="00N36000006jZtN"
                    type="hidden"
                    value="CP-GDLV-02776-OCC"
                  />
                  <input
                    id="00N36000005AqLz" //Medio
                    name="00N36000005AqLz"
                    title="Medio."
                    type="hidden"
                    value="GDLV-P"
                  />

                  <Col xs={12} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerButton}`}>
                        <button
                          className={`${styles.colContainerButton__button}`}
                          type="submit"
                          name="submit"
                          onClick={handleEnviar}
                        >
                          Enviar
                        </button>
                        <Popup
                          show={showPopup}
                          handleClose={togglePopup}
                          data={responseData}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default withRouter(App);
