import React from "react";
import PropTypes from "prop-types";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import stylesBoton from "../components/BotonGeneral/BotonGeneral.module.css";
import imagen from "../images/png/LogoSU.png";
import ojo from "../images/png/Ojo.png";
import warning from "../images/png/Warning.png";
import florArbol from "../images/png/FlorArbol.png";
import arbolFlor from "../images/png/ArbolFlor.png";

const BTNCOMPROBANTE = "DESCARGAR COMPROBANTE";
const pase = "Pase de consulta";
const salud = "para mejorar tu salud visual";
const etiquetaNombre = "Nombre del paciente:";
const etiquetaClinica = "Clínica Oftalmológica salauno sucursal:";
const etiquetaFecha = "Fecha:";
const etiquetaHora = "Hora:";
const etiquetaComo = "Cómo llegar:";
const direccion = "Dirección:";
const nota = `Debes disponer de al menos 90 min.
Trae tus lentes o lentes de contacto actuales.
Como es posible que te dilaten los ojos te recomendamos venir acompañado.`;
const dudas = "Gracias por elegir salauno. Si tienes dudas llama al: 4163-6969";
const imprimir = `No es necesario imprimir este comprobante.
Salauno cuida el planeta.`;

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
  },

  section: {
    marginLeft: 45,
    marginRight: 45,
    marginTop: 40,
    flexGrow: 1,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#00778B",
  },

  image: {
    height: 52,
    width: 190,
  },

  texto: {
    color: "#fff",
    fontSize: 8,
  },

  seccionFoto: {
    backgroundColor: "#00778B",
    height: 80,
    width: "100%",
    borderTopLeftRadius: 17,
    borderTopRightRadius: 17,
    alignItems: "flex-end",
    justifyContent: "center",
  },

  seccionTitulo: {
    height: 84,
    backgroundColor: "#EFEFEF",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#00778B",
  },

  seccionNota: {
    height: 75,
    borderColor: "#00778B",
    flexDirection: "row",
    borderBottomWidth: 1,
  },

  ojoContenedor: {
    flex: 2,
    borderRightWidth: 1,
    borderColor: "#00778B",
    alignItems: "center",
    justifyContent: "center",
  },

  ojo: {
    width: "60%",
  },

  textoTitulo: {
    flex: 7,
    borderRightWidth: 1,
    borderStyle: "dotted",
    borderColor: "#00778B",
    paddingLeft: 15,
    justifyContent: "center",
  },

  textoPase: {
    fontSize: 31,
    color: "#00778B",
  },

  textoSalud: {
    fontSize: 21,
    color: "#00778B",
  },

  mexStyle: {
    fontSize: 17,
    color: "#00778B",
    transform: "rotate(-90deg)",
  },

  mexTexto: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },

  footer: {
    height: 75,
    marginLeft: 60,
    marginRight: 60,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  notaImagen: {
    flex: 2,
    backgroundColor: "#FFCE00",
    alignItems: "center",
    justifyContent: "center",
  },

  informacionSeccion: {
    height: 60,
    borderBottomWidth: 1,
    borderColor: "#00778B",
  },

  textoInformacion: {
    color: "#00778B",
    fontSize: 18,
    paddingLeft: 35,
    paddingRight: 15,
    textTransform: "uppercase",
    paddingTop: 5,
  },

  textoInformacion2: {
    color: "#00778B",
    fontSize: 14,
    paddingLeft: 35,
    paddingRight: 35,
    paddingTop: 5,
  },

  textoEtiqueta: {
    paddingLeft: 25,
    paddingTop: 10,
    fontSize: 14,
    color: "#00778B",
  },

  download: {
    height: 17,
    width: 14,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
  },

  textoDudas: {
    fontSize: 13,
    color: "#00778B",
    paddingTop: 5,
  },

  seccionDuda: {
    alignItems: "center",
    justifyContent: "center",
    height: 30,
  },

  textoImprimir: {
    textAlign: "center",
    marginLeft: 10,
    marginRight: 10,
    width: 310,
    fontSize: 10,
    color: "#00778B",
  },
});

const comprobanteHoja = (
  nombre,
  dia,
  hora,
  clinica,
  comoLlegar,
  direccionClinica
) => (
  <Page size="A4" style={styles.page}>
    <View style={styles.section}>
      <View style={styles.seccionFoto}>
        <Image src={imagen} style={styles.image} />
      </View>
      <View style={styles.seccionTitulo}>
        <View style={styles.ojoContenedor}>
          <Image src={ojo} style={styles.ojo} />
        </View>
        <View style={styles.textoTitulo}>
          <Text style={styles.textoPase}>{pase}</Text>
          <Text style={styles.textoSalud}>{salud}</Text>
        </View>
        <View style={styles.mexTexto}>
          <Text style={styles.mexStyle}>MÉX</Text>
        </View>
      </View>
      <View style={styles.informacionSeccion}>
        <Text style={styles.textoEtiqueta}>{etiquetaNombre}</Text>
        <Text style={styles.textoInformacion}>{nombre}</Text>
      </View>
      <View style={styles.informacionSeccion}>
        <Text style={styles.textoEtiqueta}>{etiquetaClinica}</Text>
        <Text style={styles.textoInformacion}>{clinica}</Text>
      </View>
      <View style={[styles.informacionSeccion, { flexDirection: "row" }]}>
        <View style={{ flex: 1 }}>
          <Text style={styles.textoEtiqueta}>{etiquetaFecha}</Text>
          <Text style={styles.textoInformacion}>{dia}</Text>
        </View>
        <View style={{ flex: 1, borderLeftWidth: 1, borderColor: "#00778B" }}>
          <Text style={styles.textoEtiqueta}>{etiquetaHora}</Text>
          <Text style={styles.textoInformacion}>{hora}</Text>
        </View>
      </View>
      <View style={[styles.informacionSeccion, { height: 80 }]}>
        <Text style={styles.textoEtiqueta}>{direccion}</Text>
        <Text style={styles.textoInformacion}>{direccionClinica}</Text>
      </View>
      <View style={[styles.informacionSeccion, { height: 180 }]}>
        <Text style={styles.textoEtiqueta}>{etiquetaComo}</Text>
        <Text
          style={styles.textoInformacion2}
        >{`Auto: ${comoLlegar.Auto}`}</Text>
        <Text
          style={styles.textoInformacion2}
        >{`Metro: ${comoLlegar.Metro}`}</Text>
        <Text
          style={styles.textoInformacion2}
        >{`Metrobus: ${comoLlegar.Metrobus}`}</Text>
      </View>
      <View style={styles.seccionNota}>
        <View style={styles.notaImagen}>
          <Image src={warning} style={styles.ojo} />
        </View>
        <View
          style={{
            flex: 8,
            justifyContent: "center",
            borderLeftWidth: 1,
            borderColor: "#00778B",
          }}
        >
          <Text style={styles.textoEtiqueta}>{nota}</Text>
        </View>
      </View>
      <View style={styles.seccionDuda}>
        <Text style={styles.textoDudas}>{dudas}</Text>
      </View>
    </View>
    <View style={styles.footer}>
      <Image src={florArbol} style={{ height: 50 }} />
      <Text style={styles.textoImprimir}>{imprimir}</Text>
      <Image src={arbolFlor} style={{ height: 50 }} />
    </View>
  </Page>
);

const Documento = (props) => {
  const {
    nombre,
    fecha,
    hora,
    sucursal,
    comoLlegar,
    direccion,
    nombre2,
  } = props;
  return (
    <Document>
      {comprobanteHoja(nombre, fecha, hora, sucursal, comoLlegar, direccion)}
      {nombre2
        ? comprobanteHoja(nombre2, fecha, hora, sucursal, comoLlegar, direccion)
        : null}
    </Document>
  );
};

export default class PdfConfirmacion extends React.Component {
  constructor(props) {
    super(props);
    this.state = { url: null };
    // if (process.env.NODE_ENV !== 'production') { console.tron.log(`[PdfConfirmacion] Como Llegar ${props.comoLlegar}`) }
  }

  onRender = ({ blob }) => {
    this.setState({ url: URL.createObjectURL(blob) });
  };
  shouldComponentUpdate(nextProps, nextState) {
    // console.log("nextProps:", nextProps, "nextState:", nextState);
  }

  render() {
    const {
      nombre: nombreProp,
      nombre2: nombre2Prop,
      fecha: fechaProp,
      hora: horaProp,
      sucursal: sucursalProp,
      comoLlegar: comoLlegarProp,
      direccion: direccionProp,
    } = this.props;
    return (
      <button className={stylesBoton.BotonGeneralActivo_blancoPresencial} type="button">
        <PDFDownloadLink
          document={
            <Documento
              nombre={nombreProp}
              nombre2={nombre2Prop}
              fecha={fechaProp}
              hora={horaProp}
              sucursal={sucursalProp}
              comoLlegar={comoLlegarProp}
              direccion={direccionProp}
            />
          }
          fileName="Pase de consulta.pdf"
        >
          {BTNCOMPROBANTE}
        </PDFDownloadLink>
      </button>
    );
  }
}

PdfConfirmacion.propTypes = {
  nombre: PropTypes.string,
  fecha: PropTypes.any,
  hora: PropTypes.any,
  sucursal: PropTypes.any,
  comoLlegar: PropTypes.any,
  direccion: PropTypes.any,
  nombre2: PropTypes.any,
};

PdfConfirmacion.defaultProps = {
  nombre: "",
  fecha: null,
  hora: null,
  sucursal: null,
  comoLlegar: null,
  direccion: null,
  nombre2: null,
};
