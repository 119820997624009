/* eslint-disable no-undef */
import React, { useState } from 'react';
import axios from 'axios';
import FormaDePagoTarjeta from './FormaDePagoTarjeta';
import DiagnosticosData from '../../../data/Diagnosticos.json';
import moment from 'moment';

const clienteAxios = axios.create({
	//COMBAK
	baseURL: 'https://formasdepago.azurewebsites.net/api', //!Producción
	// baseURL: "https://formasdepago-testing.azurewebsites.net/api", //!StageDev
	// baseURL: "http://localhost:7071/api", //!Desarrollo localk
});
let indexDiagnostic;

let peticionFormaDePagoTarjeta = 'Hacer peticion';

export default ({
	datosParaPago,
	history,
	location,
	state,
	pagoOrientacion,
	getPeticion,
}) => {
	const momentNow = moment().format('YYYY');
	const momentDurationCard = (parseInt(moment().format('YYYY')) + 5).toString();
	const [datos, setDatos] = useState({
		name: datosParaPago.nombre,
		email: datosParaPago.email,
		phone: datosParaPago.telContact,
		totalPagar: datosParaPago.totalPagar,
		token: '',
		number: '',
		error: '',
		tipoTarjeta: '',
	});
	const [peticion] = useState('Hacer peticion');
	const [erroresConekta] = useState({
		tarjetaDeclinada: 'conekta.errors.processing.bank.declined',
		fondosInsuficientes: 'conekta.errors.processing.bank.insufficient_funds',
		nombreInvalido:
			'conekta.errors.parameter_validation.customer_info.name.missing',
	});
	const [respuestaErrores] = useState({
		tarjetaDeclinada:
			'La tarjeta ingresada ha sido declinada. Por favor intenta con otro método de pago.',
		fondosInsuficientes:
			'Esta tarjeta no tiene suficientes fondos para completar la compra.',
		nombreInvalido:
			'El nombre que a introducido es invalido, intente de nuevo.',
	});
	const realizarPago = (e) => {
		e.preventDefault();
		setDatos({
			...datos,
			error: '',
		});
		peticionFormaDePagoTarjeta = 'Haciendo peticion';
		getPeticion(peticionFormaDePagoTarjeta);
		function scriptLoaded() {
			let form = document.getElementById('card-form');
			let number = document.getElementById('number').value;
			let cvc = document.getElementById('cvc').value;
			let exp_month = document.getElementById('exp_month').value;
			let exp_year = document.getElementById('exp_year').value;
			Conekta.setPublicKey(process.env.REACT_APP_CONEKTA_API_KEY);
			const conektaSuccessResponseHandler = async (token) => {
				if (
					Conekta.card.validateNumber(number) &&
					Conekta.card.validateExpirationDate(exp_month, exp_year) &&
					Conekta.card.validateCVC(cvc)
				) {
					const datosPaciente = {
						name: datos.name,
						email: datos.email,
						phone: datos.phone,
						totalPagar: datos.totalPagar,
						token: token.id,
						error: datos.error,
						idCita: datosParaPago.idCita,
					};
					const res = await clienteAxios.post(
						`/FormaDePagoTarjeta`, //!Produccion y desarrollo local
						datosPaciente
					);
					if (res.data !== '') {
						if (res.data.details) {
							if (
								res.data.details[1].code ===
								'conekta.errors.parameter_validation.payment_method.expires_at.invalid_datatype'
							) {
								setDatos({
									...datos,
									error: 'El año de experacion es invalido',
								});
							}
						}
						if (res.data.mensaje) {
							if (
								res.data.mensaje[0].code === erroresConekta.tarjetaDeclinada
							) {
								setDatos({
									...datos,
									error: respuestaErrores.tarjetaDeclinada,
								});
							} else if (
								res.data.mensaje[0].code === erroresConekta.fondosInsuficientes
							) {
								setDatos({
									...datos,
									error: respuestaErrores.fondosInsuficientes,
								});
							} else if (
								res.data.mensaje[1].code === erroresConekta.nombreInvalido
							) {
								setDatos({ ...datos, error: respuestaErrores.nombreInvalido });
							} else {
								setDatos({ ...datos, error: res.data.mensaje[0].message });
							}
						}
						peticionFormaDePagoTarjeta = 'Hacer peticion';
						getPeticion(peticionFormaDePagoTarjeta);
					} else {
						//!Aqui se redrige a confirmacion
						let { historialFlujo } = state;
						let { flujoStep } = historialFlujo;
						historialFlujo.flujoStep = flujoStep + 1;
						const tipoPagoCita = {
							tipo: 'Tarjeta',
							clabe: '',
						};
						let { header } = state;
						const { diagnostico } = state;
						const rutaA = '/PagoDeCita';
						header.push({
							id: 5,
							titulo: 'Pado de la cita',
							datos: 'Datos del pago de la cita',
							ruta: rutaA,
						});
						let newHistory = {
							...state,
							header,
							historialFlujo,
							tipoPagoCita,
						};
						newHistory.cita.pago = 'Pagado';
						navegaPorServicio(state.diagnostico.nombreServicio);
						//!Manda al cliente a la pagina de confirmacion
						history.push(
							diagnostico.routes[location.pathname] + location.search,
							newHistory
						);
					}
				} else {
					setDatos({
						...datos,
						error: 'Hubo un error en los datos. verifiquelos porfavor',
					});
					peticionFormaDePagoTarjeta = 'Hacer peticion';
					getPeticion(peticionFormaDePagoTarjeta);
				}
			};
			const conektaErrorResponseHandler = (response) => {
				setDatos({ ...datos, error: response.message_to_purchaser });
				peticionFormaDePagoTarjeta = 'Hacer peticion';
				getPeticion(peticionFormaDePagoTarjeta);
			};
			Conekta.Token.create(
				form,
				conektaSuccessResponseHandler,
				conektaErrorResponseHandler
			);
		}
		let scriptConekta = document.createElement('script');
		scriptConekta.setAttribute(
			'src',
			'https://cdn.conekta.io/js/latest/conekta.js'
		);
		document.body.appendChild(scriptConekta);
		scriptConekta.addEventListener('load', scriptLoaded, false);
	};
	const handleChangeTipoTarjeta = (e) => {
		let tipoTarjetConketa = e.target.value;
		function scriptLoaded() {
			Conekta.setPublicKey(process.env.REACT_APP_CONEKTA_API_KEY);
			setDatos({
				...datos,
				tipoTarjeta: Conekta.card.getBrand(tipoTarjetConketa),
			});
		}
		let scriptConektaTipoTarjeta = document.createElement('script');
		scriptConektaTipoTarjeta.setAttribute(
			'src',
			'https://cdn.conekta.io/js/latest/conekta.js'
		);
		document.body.appendChild(scriptConektaTipoTarjeta);
		scriptConektaTipoTarjeta.addEventListener('load', scriptLoaded, false);
	};
	const handleChange = (e) => {
		setDatos({ ...datos, [e.target.name]: e.target.value });
	};
	const getObjectByService = (nameService) => {
		return Object.keys(DiagnosticosData).find(
			(key) => DiagnosticosData[key].nombreServicio === nameService
		);
	};
	const setHistoryForNavigation = (index) => {
		indexDiagnostic = index;
	};
	const navegaPorServicio = (servicio) => {
		let diagnosticoIndex = getObjectByService(servicio);
		setHistoryForNavigation(diagnosticoIndex);
	};
	return (
		<FormaDePagoTarjeta
			datos={datos}
			realizarPago={realizarPago}
			error={datos.error}
			handleChange={handleChange}
			handleChangeTipoTarjeta={handleChangeTipoTarjeta}
			tipoTarjeta={datos.tipoTarjeta}
			peticion={peticion}
			pagoOrientacion={pagoOrientacion}
			momentNow={momentNow}
			momentDurationCard={momentDurationCard}
		/>
	);
};
