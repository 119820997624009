import React from "react";
import { Container, Row, Col } from "reactstrap";
import styles from "./FormaDePagoSelectorStyles.module.css";

export default ({ selectTipoDePago, tipoPago }) => {
  return (
    <Container
      className={`${styles.containerSelector} d-flex justify-content-center `}
    >
      <Row className={`${styles.rowContainerFormasDePagoSelector}`}>
        <Col className={styles.colMetodoDePago} lg={12}>
          <p className={styles.colMetodoDePago__p}>Método de Pago</p>
        </Col>
        <Col lg={12} className={`${styles.colCard} px-3 `}>
          <label htmlFor="card" className={`${styles.colCard__label}`}>
            <input
              type="radio"
              value="card"
              id="card"
              checked={tipoPago === "card"}
              onChange={selectTipoDePago}
              className={`${styles.colCard__input}`}
            />
            Tarjeta Débito o Credito
          </label>
        </Col>
        <Col lg={12} className={`${styles.colSpei} px-3`}>
          <label htmlFor="spei" className={`${styles.colSpei__label}`}>
            <input
              type="radio"
              value="spei"
              id="spei"
              checked={tipoPago === "spei"}
              onChange={selectTipoDePago}
              className={`${styles.colSpei__input}`}
            />
            Pago SPEI
          </label>
        </Col>
        <Col lg={12} className={`${styles.colOxxo} px-3 `}>
          <label htmlFor="oxxo_cash" className={`${styles.colOxxo__label}`}>
            <input
              type="radio"
              value="oxxo_cash"
              id="oxxo_cash"
              checked={tipoPago === "oxxo_cash"}
              onChange={selectTipoDePago}
              className={`${styles.colOxxo__input}`}
            />
            Oxxo Pay
          </label>
        </Col>
      </Row>
    </Container>
  );
};
