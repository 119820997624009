/* eslint-disable no-restricted-globals */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { PulseLoader } from "react-spinners";
import moment from "moment";
import PropTypes from "prop-types";
import axios from "axios";
import { Col, Row, Container, Modal, ModalHeader, ModalBody } from "reactstrap";

import BotonGeneral from "../components/BotonGeneral";
import { DropdownHorarios, DropdownHoras } from "../components/Dropdown";
import { TarjetasDia } from "../components/Tarjetas";
import { FullCalendario } from "../components/Calendario";

import styles from "../css/Fecha.module.css";
import stylesLentes from "../css/SinLentes.module.css";
import stylesConfirmacion from "../css/ConfirmacionStyles.module.css";

import imgModalOcupada from "../images/svg/CancelarModal.svg";
import imgNoDisponible from "../images/svg/NoDisponible.svg";
import imgNoDisponiblePromo from "../images/svg/NoDisponiblePromo.svg";
import sprite from "../images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg";

import clinicas from "../data/clinica";
import DiagnosticosData from "../data/Diagnosticos.json";

let indexDiagnostic;

let dateTime = "";
let dateTimePromo1 = "";
let dateTimePromo2 = "";

const calendarIdOMO = [0, 3901848, 3901815, 4044835];
const TXT_HEADER_FECHA_OMO =
  "Elijan la fecha para a tomar su orientación en línea";
const TXT_HEADER_FECHA = "Elijan la fecha para acudir a nuestra clínica:";
const MODAL_TEXTO_OCUPADA = (
  <div>
    <p>
      Lo sentimos, se ha ocupado el horario de la cita, por favor da click en
      aceptar y elige nuevos horarios.
    </p>
  </div>
);
const creacionDeCita = new moment();
let tiempoAntesDeCita;
let stringDia = "";
let intDia = null;
let stringMes = "";
let stringHoraTextual = "";
class Fecha extends Component {
  constructor(props) {
    super(props);

    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[Fecha] History`,
        value: props.location.state,
        important: true,
      });
    }

    let { reagendar, prioridad, diagnostico, serviceId, para } =
      props.history.location.state;
    const { paraQuien } = para;

    let servicioId = prioridad !== null ? serviceId : diagnostico.idServicio;
    let contacto =
      prioridad !== null ? props.location.state.cita.contacto : null;

    //COMBAK
    //'https://salauno-agenda-new-testing-agenda.azurewebsites.net/salauno'
    const baseUrlNode =
      window.location.hostname === "citas.salauno.com.mx"
        ? "https://salauno-nodeserver.azurewebsites.net/acuity"
        : "https://salauno-nodeserver.azurewebsites.net/acuity";
    const baseUrl =
      window.location.hostname === "citas.salauno.com.mx"
        ? "https://agenda.salauno.com.mx/salauno"
        : "https://salauno-agenda-new-erika.azurewebsites.net/salauno";//https://salauno-agenda-new-erika.azurewebsites.net/salauno
        //: "https://salauno-nodeserver.azurewebsites.net/acuity";

    this.state = {
      activo: false,
      calendarID: null,
      duration: null,
      existeBtn: true,
      fechasValidas: [],
      horarioActivo: "AM",
      horariosAM: [],
      horariosPM: [],
      loadingContinuar: false,
      loadingDay: true,
      loadingHours: true,
      modalOcupada: false,
      notAvailable: false,
      paraQuien,
      promocion: false,
      reagendar,
      serviceId: servicioId,
      width: window.innerWidth,
      prioridad,
      contacto,
      baseUrl,
      baseUrlNode,
      esTeleconsulta : false,
    };
  }

  componentDidMount() {
    const { urlParams } = this.props.history.location.state;
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.handleWindowSizeChange);

    let { clinica, para, prioridad, diagnostico } =
      this.props.history.location.state;
    let { paraQuien } = para;
    let servicioId = diagnostico.idServicio;
    let calendarId = 0;

    if (process.env.NODE_ENV !== "production") {
      console.tron.log(`[Fecha] @componentDidMount ${servicioId} `);
    }

    if (prioridad != null && prioridad > 0) {
      calendarId = calendarIdOMO[prioridad];
    } else {
      let { calendarId: calendarioClinicaId } =
        clinicas[clinica.id].servicios[servicioId];
      calendarId = calendarioClinicaId;
    }

    if (
      servicioId &&
      (servicioId === 1747562 ||
        servicioId === 1748018 ||
        servicioId === 2719476 ||
        urlParams.medio === "GDLV" ||
        urlParams.cmp === "C-MKT-LASIK-001")
    ) {
      paraQuien = 1;
      if (process.env.NODE_ENV !== "production") {
        console.tron.log(
          `[Fecha] Para quien no es para una persona: ${paraQuien}`
        );
      }
    }

    const duration = this.getDuration();

    this.getDates(calendarId).then((datesCalendars) => {

      if (process.env.NODE_ENV !== "production") {
        console.tron.display({
          name: `[Fecha] dates calendar`,
          value: datesCalendars,
        });
      }

      this.setState({
        ...datesCalendars,
        duration,
        calendarID: calendarId,
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  getDuration = async () => {
    const { serviceId, baseUrl } = this.state;
    await axios
      .get(`${baseUrl}/Duration`)
      .then((response) => {
        const responseLength = response.data.length;

        for (let i = 0; i < responseLength; i += 1) {
          if (response.data[i].id === serviceId) {
            if (process.env.NODE_ENV !== "production") {
              console.tron.log(
                `[Fecha] duration: ${response.data[i].duration}`
              );
            }
            return response.data[i].duration;
          }
        }
        return null;
      })
      .catch((errorDuracion) => {
        if (process.env.NODE_ENV !== "production") {
          console.tron.error(
            `[Fecha] No se pudo obtener la duración de la cita: ${errorDuracion}`
          );
        }
        console.error(
          `[Fecha] No se pudo obtener la duración de la cita: ${errorDuracion}`
        );
        return null;
      });
  };

  getDates = async (calendarID) => {
    const { urlParams } = this.props.history.location.state;
    if (process.env.NODE_ENV !== "production") {
      console.tron.log(`[Fecha] calendar ${calendarID}`);
    }

    const f = new Date();
    let year = f.getFullYear();
    const month = `${year}-${f.getMonth() + 1}`;
    let nextMonth = f.getMonth() + 2;

    const { serviceId, prioridad, baseUrl, baseUrlNode } = this.state;

    if (nextMonth > 12) {
      nextMonth = 1;
      year += 1;
    }

    let omo = prioridad && prioridad > 0 ? true : false;

    const nextMonthYear = `${year}-${nextMonth}`;

    let medioCC = null;
    medioCC =
      urlParams.isContactCenter !== null
        ? "CitaDeseada"
        : urlParams.medio === "CitaDeseada"
        ? "CitaDeseada"
        : "noEsCC";

    const monthPost = {
      ServiceID: serviceId,
      calendarID,
      month,
      next_month: nextMonthYear,
      medio: medioCC,
    };

    const monthPostNode = {
      appointmentType: serviceId,
      calendarID,
      mes: month,
      mesSiguiente: nextMonthYear,
      numeroDias: medioCC === "CitaDeseada" ? 30 : 10,
    };

    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[Fecha] Parametros de petición de Fechas:`,
        value: { monthPost },
      });
    }

    let endpoint = omo
      ? `${baseUrl}/FechasOMO`
      : `${baseUrlNode}/fechasPorClinica`;
    console.log("monthPostNode:", monthPostNode);
    return axios
      .post(endpoint, omo ? monthPost : monthPostNode)
      .then((response) => {
        if (process.env.NODE_ENV !== "production") {
          console.tron.display({
            name: `[Fecha] Respuesta Meses:`,
            value: { respuesta: response.data },
          });
        }

        if (response.data.length === 0) {
          return { notAvailable: true };
        }

        const fechas = this.getResponseFecha(response);
        return fechas;
      })
      .catch((errorFechas) => {
        console.error(`[Fecha] Error al obtener datos del mes: ${errorFechas}`);
        if (process.env.NODE_ENV !== "production") {
          console.tron.error(
            `[Fecha] Error al obtener datos del mes: ${errorFechas}`
          );
        }
        return { notAvailable: true };
      });
  };

  getResponseFecha = (response) => {
    let fechasValidas = response.data;
    let { promocion, notAvailable, existeBtn } = this.state;

    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[Fecha] Fechas válidas`,
        value: fechasValidas,
      });
    }

    if (fechasValidas.length === 0) {
      notAvailable = true;
      existeBtn = false;
    } else {
      this.loadHours(fechasValidas[0].date);
    }

    return {
      loadingDay: false,
      fechasValidas,
      promocion,
      notAvailable,
      existeBtn,
    };
  };

  aceptarOcupada = () => {
    this.toggleOcupada();
    this.loadHours();
  };

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  asignPostRoute = () => {
    const { paraQuien, prioridad, baseUrl, baseUrlNode } = this.state;
    let postRoute = `${baseUrlNode}/horariosPorClinica`;

    if (paraQuien === 3) {
      postRoute = `${baseUrl}/Times2`;
    }

    if (prioridad && prioridad > 0) {
      postRoute = `${baseUrl}/HorariosOMO`;
    }

    if (process.env.NODE_ENV !== "production") {
      console.tron.log(`[Fecha] ${postRoute}`);
    }

    return postRoute;
  };

  getTimes = (endpoint, dateInfo) => {
    const { nombreServicio } = this.props.location.state.diagnostico;
    axios
      .post(endpoint, dateInfo)
      .then((response) => {
        let { data } = response;
        if (process.env.NODE_ENV !== "production") {
          console.tron.display({
            name: `[Fecha] Respuesta Horarios:`,
            value: { data },
          });
        }

        let { horariosAM, horariosPM, horarioActivo } = this.state;
        const horarios = response.data;

        horariosAM = [];
        horariosPM = [];
        horarios.forEach((hora) => {
          if (hora.hour < 12) {
            if (nombreServicio === "OMO") {
              let HorarioDeLaCita = hora.time;
              let duration =
                creacionDeCita.diff(HorarioDeLaCita, "minutes") * -1;
              if (duration > 15) {
                horariosAM.push(hora);
              }
            } else {
              horariosAM.push(hora);
            }
          } else {
            if (nombreServicio === "OMO") {
              let HorarioDeLaCita = hora.time;
              let duration =
                creacionDeCita.diff(HorarioDeLaCita, "minutes") * -1;
              if (duration > 15) {
                horariosPM.push(hora);
              }
            } else {
              horariosPM.push(hora);
            }
          }
        });

        if (horariosAM.length > 0) {
          if (horarioActivo !== "AM") {
            horarioActivo = "AM";
          }
        } else {
          horarioActivo = "PM";
        }

        this.setState({
          horariosAM,
          horariosPM,
          horarioActivo,
          loadingHours: false,
          date: dateInfo.date,
        });
      })
      .catch((errorGetTimes) => {
        console.log("errorGetTimes:", errorGetTimes);
        console.error(`[Fecha] Error al obtener las horas: ${errorGetTimes}`);
        if (process.env.NODE_ENV !== "production") {
          console.tron.error(
            `[Fecha] Error al obtener las horas: ${errorGetTimes}`
          );
        }
        this.setState({
          loadingHours: false,
          notAvailable: true,
          date: dateInfo.date,
        });
      });
  };

  loadHours = (date) => {
    const cadenaObjecto = new String(date);
    this.setState({ loadingHours: true });
    const { date: dateState,esTeleconsulta } = this.state;
    const { serviceId, calendarID, prioridad } = this.state;

    let dateSelected = date ? date : dateState;
    let omo = prioridad && prioridad > 0 ? true : false;
    this.setState({ esTeleconsulta: false });
    if (calendarID !== null) {
      const dateParams = {
        ServiceID: serviceId,
        calendarID,
        date: dateSelected,
      };
      const dateParamsNode = {
        appointmentType: serviceId,
        calendarID,
        date: dateSelected,
      };

      if (process.env.NODE_ENV !== "production") {
        console.tron.display({
          name: `[Fecha] Parametros de petición Horas`,
          value: dateParams,
        });
      }

      const postRoute = this.asignPostRoute();
      console.log("dateInfo:", omo ? dateParams : dateParamsNode);
      this.getTimes(postRoute, omo ? dateParams : dateParamsNode);
    }
  };

  textofecha = (dateTime) => {
    let mes = "";
    let dia = "";
    let numberDia = null;
    const diasSemana = [
      { numberDay: 0, textDay: "Domingo" },
      { numberDay: 1, textDay: "Lunes" },
      { numberDay: 2, textDay: "Martes" },
      { numberDay: 3, textDay: "Miercoles" },
      { numberDay: 4, textDay: "Jueves" },
      { numberDay: 5, textDay: "Viernes" },
      { numberDay: 6, textDay: "Sabado" },
    ];
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    // console.log('dateTime:', dateTime)

    numberDia = moment(dateTime.time).day();
    diasSemana.map((diaSemana) => {
      if (diaSemana.numberDay === numberDia) {
        stringDia = diaSemana.textDay;
      }
      return null;
    });
    mes = dateTime.time.substring(5, 7);
    dia = dateTime.time.substring(8, 10);
    intDia = dateTime.time.substring(8, 10);

    const mesTexto = meses[parseInt(mes, 10) - 1];
    stringMes = meses[parseInt(mes, 10) - 1];

    if (this.props.location.state.diagnostico.descripcion === "Promo 2x1") {
      dateTimePromo1 = dateTime.primera.time;
      dateTimePromo2 = dateTime.segunda.time;
      return `${dia} de ${mesTexto} a las ${dateTime.primera.textual} hrs. y a las ${dateTime.segunda.textual} hrs.`;
    } else {
      return `${dia} de ${mesTexto} a las ${dateTime.textual} hrs.`;
    }
  };

  selectTime = (selectedTime) => {
    const { activo } = this.state;
    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[Fecha] selectedTime`,
        value: selectedTime,
      });
    }
    stringHoraTextual = selectedTime.textual;
    tiempoAntesDeCita = creacionDeCita.diff(selectedTime.time, "hours") * -1;

    dateTime = selectedTime;
    console.log('Aqui tomaremos el serviceId para validar si es teleconsulta o no');
    console.log("dateTime:", dateTime );
    if (!activo) {
      this.setState({ activo: true });
    }

    if(dateTime.serviceId === 10027166 || dateTime.serviceId === 54531464){
      console.log('Es servicio de teleconsulta');
      this.setState({ esTeleconsulta: true });
    }else{
      console.log('No es servicio de teleconsulta');
      this.setState({ esTeleconsulta: false });
    }
  };

  setHours = (horarioActivo) => {
    this.setState({ horarioActivo });
  };

  getObjectByService = (nameService) => {
    return Object.keys(DiagnosticosData).find(
      (key) => DiagnosticosData[key].nombreServicio === nameService
    );
  };

  setHistoryForNavigation = (index) => {
    indexDiagnostic = index;
  };

  navegaPorServicio = (servicio) => {
    let diagnosticoIndex = this.getObjectByService(servicio);
    this.setHistoryForNavigation(diagnosticoIndex);
  };

  manejarClickContinuar = () => {
    this.setState({ loadingContinuar: true });
    const { history, location } = this.props;
    const { state } = location;
    const { para, cita, diagnostico, prioridad } = state;

    let { paraQuien } = para;
    let { header, historialFlujo } = state;
    let { flujoStep } = historialFlujo;
    historialFlujo.flujoStep = flujoStep + 1;
    const {
      serviceId,
      reagendar,
      calendarID: calendarId,
      contacto,
      baseUrl,
    } = this.state;
    let idServicio = serviceId;
    const rutaA = "/cita/horario";
    let newHistory = null;

    if (paraQuien === 3) {
      // Para mi y para alguien más aquí se apartan las citas
      const medioPost = "Agenda2.0";
      const fechaTextual = this.textofecha(dateTime);

      header.push({
        id: 2,
        titulo: "El día y en los horarios:",
        dato: fechaTextual,
      });

      const citaApartada1 = {
        firstName: "LEAD FALSO",
        lastName: "PACIENTE AGENDA2.0",
        datetime: dateTimePromo1,
        phone: "0000000000",
        email: "checadordecitaspmypam@salauno.com.mx",
        ServiceID: idServicio,
        calendarID: calendarId,
        medioagenda2: medioPost,
      };
      const citaApartada2 = {
        firstName: "LEAD FALSO",
        lastName: "ACOMPAÑANTE AGENDA2.0",
        datetime: dateTimePromo2,
        phone: "0000000000",
        email: "checadordecitaspmypam@salauno.com.mx",
        ServiceID: idServicio,
        calendarID: calendarId,
        medioagenda2: medioPost,
      };
      newHistory = {
        ...state,
        header,
        diagnostico,
        historialFlujo,
        cita: {
          datetime1: dateTimePromo1,
          datetime2: dateTimePromo2,
          response1: null,
          response2: null,
          citaApartada1,
          citaApartada2,
          fechaTextual,
          diaSemana: stringDia,
          horaCita: stringHoraTextual,
          horario: this.state.horarioActivo,
          diaMes: intDia,
          mes: stringMes,
        },
        calendarId: calendarId,
        serviceId: serviceId,
      };

      axios
        .post(`${baseUrl}/Appointment`, citaApartada1)
        .then((response) => {
          if (process.env.NODE_ENV !== "production") {
            console.tron.log(
              `[Fecha] False Appointment response1: ${JSON.stringify(
                response.data
              )}`
            );
          }
          newHistory.cita.response1 = response.data;

          if (newHistory.cita.response1 === "not_available") {
            this.setState({ loadingContinuar: false });
            this.toggleOcupada();
          } else {
            axios
              .post(`${baseUrl}/Appointment`, citaApartada2)
              .then((responseAppo2) => {
                if (process.env.NODE_ENV !== "production") {
                  console.tron.log(
                    `[Fecha] False Appointment response2: ${JSON.stringify(
                      responseAppo2.data
                    )}`
                  );
                }
                newHistory.cita.response2 = responseAppo2.data;

                if (newHistory.cita.response2 === "not_available") {
                  this.setState({ loadingContinuar: false });
                  this.toggleOcupada();
                } else {
                  this.navegaPorServicio(state.diagnostico.nombreServicio);
                  history.push(
                    diagnostico.routes[location.pathname] + location.search,
                    newHistory
                  );
                }
              })
              .catch((errorApartaSegunda) => {
                console.error(
                  `[Fecha] Error al apartar segunda cita ${errorApartaSegunda}`
                );
              });
          }
        })
        .catch((errorApartaPrimera) => {
          console.error(
            `[Fecha] Error al apartar primera cita ${errorApartaPrimera}`
          );
        });
    } else {
      // Citas de una persona
      let fechaTextual = this.textofecha(dateTime);

      if (reagendar) {
        header.pop();
      }

      header.push({
        id: 4,
        titulo: "El día y el horario:",
        dato: fechaTextual,
        ruta: rutaA,
      });

      newHistory = {
        ...state,
        header,
        historialFlujo,
        cita: {
          dateTime: dateTime.time,
          fechaTextual,
          contacto,
          diaSemana: stringDia,
          horaCita: stringHoraTextual,
          horario: this.state.horarioActivo,
          diaMes: intDia,
          mes: stringMes,
        },
        calendarId: dateTime.calendarId,
        serviceId: dateTime.serviceId,
        tiempoAntesDeCita,
      };

      if (reagendar) {
        const reagendarData = {
          id: cita.response.id,
          datetime: dateTime.time,
        };

        let nextUrl = prioridad !== null ? "/confirmacionOMO" : "/Confirmacion";

        newHistory = {
          ...state,
          header,
          historialFlujo,
          cita: {
            dateTime: dateTime.time,
            fechaTextual,
            diaSemana: stringDia,
            horaCita: stringHoraTextual,
            horario: this.state.horarioActivo,
            diaMes: intDia,
            mes: stringMes,
          },
          calendarId: dateTime.calendarId,
          serviceId: dateTime.serviceId,
        };

        axios
          .post(`${baseUrl}/Reschedule`, reagendarData)
          .then((responseReschedule) => {
            if (process.env.NODE_ENV !== "production") {
              console.tron.log(
                `[Fecha] Reschedule response: ${JSON.stringify(
                  responseReschedule.data.price
                )}`
              );
            }
            console.log('La cita fue reagendada con exito.');
            alert('La cita fue modificada con éxito');
            console.log('newHistory cita response : ', newHistory.cita.response);
            newHistory.cita.response = responseReschedule.data;


            this.navegaPorServicio(state.diagnostico.nombreServicio);
            history.push(nextUrl + location.search, newHistory);
          })
          .catch((errorReagendar) => {
            console.error(`[Fecha] Error al reagendar ${errorReagendar}`);
            if (process.env.NODE_ENV !== "production") {
              console.tron.error(
                `[Fecha] Error al reagendar ${errorReagendar}`
              );
            }
          });
      } else {
        console.log("dateTime.calendarId:", dateTime.calendarId);
        console.log("newHistory:", newHistory);
        this.navegaPorServicio(state.diagnostico.nombreServicio);
        history.push(
          diagnostico.routes[location.pathname] + location.search,
          newHistory
        );
      }
    }
  };

  manejarClickPromoNoDisponible = () => {
    window.location.href = "/";
  };

  toggleOcupada = () => {
    this.setState((prevState) => ({
      modalOcupada: !prevState.modalOcupada,
    }));
  };

  renderNotAvailable = (promocion) => {
    if (promocion) {
      return (
        <>
          <Row className={styles.rowNotAvailable}>
            <img
              src={imgNoDisponiblePromo}
              alt=""
              className={styles.notAvailable}
            />
          </Row>
        </>
      );
    }
    return (
      <Row className={styles.rowNotAvailable}>
        <img src={imgNoDisponible} alt="" className={styles.notAvailable} />
      </Row>
    );
  };

  renderFecha = (fechasValidas, loadingDay, hasSlick) => {
    console.log("fechasValidas:", fechasValidas);
    const { urlParams, historialFlujo } = this.props.history.location.state;
    const { tipoConsulta } = historialFlujo;
    let bodyFecha = null;
    let medioCC = null;
    if (loadingDay || fechasValidas === "") {
      bodyFecha = (
        <div className={styles.sweetLoading}>
          <PulseLoader sizeUnit="px" size={15} margin="4px" color="#00748c" />
        </div>
      );
    } else {
      medioCC =
        urlParams.isContactCenter !== null
          ? "isContactCenter"
          : urlParams.medio === "CitaDeseada"
          ? "CitaDeseada"
          : "noEsCC";

      bodyFecha =
        medioCC === "noEsCC" ? (
          <TarjetasDia
            dias={fechasValidas}
            loadHours={this.loadHours}
            slick={hasSlick}
          />
        ) : (
          <FullCalendario dias={fechasValidas} loadHours={this.loadHours} />
        );
      // urlParams.medio !== 'CitaDeseada' ? (
      //   bodyFecha = urlParams.isContactCenter !== null && (
      //     <FullCalendario dias={fechasValidas} loadHours={this.loadHours} />
      //   );
    }

    return (
      <Row className={`${styles.row_fecha}`}>
        <Col
          className={`${styles.fechaArea} ${styles.fechaElement}`}
          xl={{ size: 12 }}
        >
          <p className={`${styles.fechaArea__p}`}>
            {tipoConsulta === "remoto"
              ? TXT_HEADER_FECHA_OMO
              : TXT_HEADER_FECHA}
          </p>
          {/* <SeccionHeader title={TXT_HEADER_FECHA} /> */}
          {bodyFecha}
        </Col>
      </Row>
    );
  };

  renderHorario = (horarioActivo, loadingDay) => {
    let bodyHorario = null;

    const horariosData = {
      select: this.setHours,
      options: horarioActivo,
    };

    if (loadingDay) {
      bodyHorario = (
        <div className={styles.sweetLoading}>
          <PulseLoader sizeUnit="px" size={15} margin="4px" color="#00748c" />
        </div>
      );
    } else {
      bodyHorario = <DropdownHorarios data={horariosData} />;
    }

    return (
      <Col
        lg={6}
        className={`py-lg-5 ${styles.fechaHeaderLeft} ${styles.alinearDerecha}`}
      >
        {bodyHorario}
      </Col>
    );
  };

  renderHoras = (horarios, loadingHours) => {
    let bodyHoras = null;
    const { paraQuien } = this.state;
    const { descripcion } = this.props.history.location.state.diagnostico;

    const horasData = {
      select: this.selectTime,
      options: horarios,
      paraQuien: paraQuien,
      descripcion,
    };

    if (loadingHours) {
      bodyHoras = (
        <div className={styles.sweetLoading}>
          <PulseLoader sizeUnit="px" size={15} margin="4px" color="#00748c" />
        </div>
      );
    } else {
      bodyHoras = <DropdownHoras data={horasData} />;
    }

    return (
      <Col
        lg={6}
        className={`py-lg-5 ${styles.fechaHeaderLeft} ${styles.alinearIzquierda}`}
      >
        {bodyHoras}
      </Col>
    );
  };
  regresar = () => {
    const { history, location } = this.props;
    const { state } = location;
    let { historialFlujo, clinica } = state;
    const { flujoStep, tipoConsulta } = historialFlujo;
    clinica.cambiarClinica = true;
    let newHistory = {
      ...state,
      historialFlujo: {
        ...state.historialFlujo,
        flujoStep: flujoStep - 1,
      },
      clinica,
    };
    tipoConsulta === "presencial"
      ? history.push("/cita/clinica", newHistory)
      : history.push("/cita/tipoLlamada", newHistory);
  };
  irAsiguienteVista = () => {
    this.manejarClickContinuar();
  };

  render() {
    const {
      modalOcupada,
      duration,
      fechasValidas,
      horarioActivo,
      horariosAM,
      horariosPM,
      loadingDay,
      loadingHours,
      notAvailable,
      promocion,
      paraQuien,
      serviceId,
      esTeleconsulta
    } = this.state;
    const { urlParams } = this.props.history.location.state;
    const hasSlick = true;
    const horarios = horarioActivo === "PM" ? horariosPM : horariosAM;

    return (
      <Container fluid className={`${styles.containerFechaCitaFluid}`}>
        <Container className={`${styles.containerFechaCita}`}>
          <>
            {notAvailable ? this.renderNotAvailable(promocion) : null}
            {!notAvailable
              ? this.renderFecha(fechasValidas, loadingDay, hasSlick)
              : null}
            <Row className={`px-3 py-3 ${styles.rowContainer}`}>
              <Col
                lg={12}
                className={`${styles.colContainerTitleHorarios} d-flex justify-content-center`}
              >
                <p className={`${styles.colContainerTitleHorarios__title}`}>
                  Elijan el horario que mejor les conviene:
                </p>
              </Col>
              {esTeleconsulta ? (
              <Col lg={12}className={`d-flex justify-content-center`}>
                <p className={`${styles.colContainerTitleHorarios__title}`}>
                  El horario que has elegido es para un servicio de teleconsulta
                </p>
              </Col>) : null}
              {!notAvailable
                ? this.renderHorario(horarioActivo, loadingDay)
                : null}
              {!notAvailable
                ? this.renderHoras(
                    horarios,
                    loadingHours,
                    paraQuien,
                    serviceId,
                    duration
                  )
                : null}
            </Row>
          </>
          <Row className={stylesLentes.rowNoLead}>
            <Col>
              <Modal
                isOpen={modalOcupada}
                toggle={this.toggleOcupada}
                id="ocupada"
              >
                <ModalHeader toggle={this.toggleOcupada} />
                <ModalBody>
                  <div className={stylesLentes.imgModalNoLead}>
                    <img src={imgModalOcupada} alt="" />
                  </div>
                  <br />
                  <p className={stylesLentes.textModalNoLead}>
                    {MODAL_TEXTO_OCUPADA}
                  </p>
                  <Row className={stylesLentes.rowBotonNoLead}>
                    <Col
                      className={`${stylesConfirmacion.col_cancelarSi}`}
                      xs={{ size: 11 }}
                      sm={{ size: 11 }}
                      md={{ size: 6 }}
                      lg={{ size: 6 }}
                      xl={{ size: 6 }}
                    >
                      <BotonGeneral
                        manejarClick={this.aceptarOcupada}
                        nombreBoton="ACEPTAR"
                        activo
                        existe
                      />
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
        <Row className={`${styles.rowContainerButtons}`}>
          <Col
            xs={12}
            className={`${styles.colContainerButtons} d-flex justify-content-center align-items-center`}
          >
            <Row className={`${styles.rowButtons}`}>
              {urlParams.ruta !== "WebsitePresencial" && (
                <Col
                  xs={12}
                  md={6}
                  className={`${styles.colContainerRegresar} ${styles.alinearDerecha} d-flex justify-content-center`}
                >
                  <button
                    className={`${styles.colContainerButtons__regresar}`}
                    onClick={this.regresar}
                  >
                    REGRESAR
                  </button>
                </Col>
              )}
              <Col
                xs={12}
                md={urlParams.ruta !== "WebsitePresencial" ? 6 : 12}
                className={`${styles.colContainerContinuar} ${
                  urlParams.ruta !== "WebsitePresencial" &&
                  styles.alinearIzquierda
                } d-flex justify-content-center`}
              >
                <button
                  className={`${styles.colContainerButtons__continuar}`}
                  disabled={this.state.activo === false ? true : false}
                  onClick={this.irAsiguienteVista}
                >
                  <Container fluid>
                    <Row>
                      <Col
                        xs={8}
                        className={`${styles.colContainerNombreBoton}`}
                      >
                        CONTINUAR
                      </Col>
                      <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                        <span className={`${styles.colContainerButtons__span}`}>
                          <svg className={`${styles.colContainerButtons__svg}`}>
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

Fecha.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  prioridad: PropTypes.any,
};

Fecha.defaultProps = {
  location: null,
  history: null,
  prioridad: null,
};

export default withRouter(Fecha);
