import React from "react";
import { Container, Row, Col } from "reactstrap";
import styles from "./FormaDePagoStyles.module.css";
import GeneralToatsContainer from "../../../components/GeneralToats/GeneralToatsContainer";

let mensajeErrorDuracion;
export default (props) => {
  const {
    handleChange,
    realizarPago,
    linkComprobante,
    datosPaciente,
    peticionFormaDePago,
    descargarComprobante,
    tipo,
    state,
  } = props;
  const { tiempoAntesDeCita } = state;
  if (descargarComprobante === true) {
    linkComprobante();
  }
  mensajeErrorDuracion =
    tipo === "spei" ? "Transferencia Bancaria" : "Pago Oxxo";
  return tiempoAntesDeCita > 6 ? (
    <Container className={`${styles.container}`}>
      <Row className={`${styles.rowContainer}`}>
        <Col lg={12} className={`${styles.colContainerForm}`}>
          <form
            onSubmit={realizarPago}
            id="speiOxxoForm"
            className={`${styles.form}`}
          >
            <Container className={`${styles.formContainer} py-3`}>
              {datosPaciente.error !== "" &&
                peticionFormaDePago === "Hacer peticion" && (
                  <GeneralToatsContainer mensajeError={datosPaciente.error} />
                )}
              <Row className={`${styles.rowTitle}`}>
                <Col lg={12} className={`${styles.colTitle}`}>
                  {tipo === "spei" ? (
                    <p className={`${styles.colTitle__title}`}>
                      Transferencia Bancaria:
                    </p>
                  ) : (
                    <p className={`${styles.colTitle__title}`}>Pago Oxxo:</p>
                  )}
                </Col>
              </Row>
              <Row className={`${styles.rowData} my-0 py-1`}>
                <Col lg={6} className={`${styles.colNameContainer}`}>
                  <Row className={`${styles.rowNameContaine}`}>
                    <Col lg={12} className={`${styles.colTitleName}`}>
                      <label className={`${styles.colTitleName__title}`}>
                        Nombre y Apellido
                      </label>
                    </Col>
                    <Col lg={12} className={`${styles.colName}`}>
                      <input
                        className={`${styles.colName__input}`}
                        type="text"
                        onChange={handleChange}
                        name="name"
                        id="name"
                        required
                        placeholder="Nombre"
                        value={datosPaciente.name}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} className={`${styles.colEmailContainer}`}>
                  <Row className={`${styles.rowEmailContainer}`}>
                    <Col lg={12} className={`${styles.colTitleEmail}`}>
                      <label className={`${styles.colTitleEmail__title}`}>
                        Correo electrónico
                      </label>
                    </Col>
                    <Col lg={12} className={`${styles.colEmail}`}>
                      <input
                        className={`${styles.colEmail__input}`}
                        type="email"
                        onChange={handleChange}
                        name="email"
                        id="email"
                        required
                        placeholder="Email"
                        value={datosPaciente.email}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </form>
        </Col>
      </Row>
    </Container>
  ) : (
    <Container className={`${styles.container}`}>
      <Row className={`${styles.rowContainer}`}>
        <Col lg={12} className={styles.colMensajeError}>
          <p className={styles.colMensajeError__p}>
            No esta disponible el pago por {mensajeErrorDuracion}. Sólo
            disponible con citas con más de 6Hr de anticipación
          </p>
        </Col>
      </Row>
    </Container>
  );
};
