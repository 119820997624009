import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import styles from "./Botones.module.css";

export default class BotonFecha extends Component {
  handleClick = () => {
    const { date, id, loadHours } = this.props;
    loadHours(date, id);
  };

  formatDateButton = () => {
    const { diaSemana, diaNumero, mes, date } = this.props;
    const fecha = new Date();

    let mesActual = fecha.getMonth() + 1;
    let contenido = "";

    if (mesActual < 10) {
      mesActual = `0${mesActual}`;
    }

    const today = `${fecha.getFullYear()}-${mesActual}-${fecha.getDate()}`;
    const tomorrow = `${fecha.getFullYear()}-${mesActual}-${
      fecha.getDate() + 1
    }`;

    let styleDayOfWeek = `${styles.semanaDia}`;
    let styleMonth = `${styles.mesDia}`;
    let textTop = "_";
    let textMiddle = "";
    let textBottom = "_";

    if (moment(date).isSame(today)) {
      textTop = "Hoy";
      textMiddle = diaNumero;
      textBottom = mes;
    } else if (moment(date).isSame(tomorrow)) {
      textTop = "Mañana";
      textMiddle = diaNumero;
      textBottom = mes;
    }

    if (textMiddle !== "") {
      // styleDayOfWeek += ` ${styles.transparente}`;
      // styleMonth += ` ${styles.transparente}`;
    } else {
      textTop = diaSemana;
      textMiddle = diaNumero;
      textBottom = mes;
    }

    contenido = (
      <div className={`${styles.containerTextBotonFecha}`}>
        <p className={`${styleDayOfWeek}`}>{textTop}</p>
        <p
          className={`${styles.dinFont} ${styles.secondaryBlue} ${styles.numeroDia}`}
        >
          {textMiddle}
        </p>
        <p className={`${styleMonth}`}>{textBottom}</p>
      </div>
    );
    return contenido;
  };

  render() {
    const { estiloClassname } = this.props;

    return (
      <button
        className={estiloClassname}
        onClick={this.handleClick}
        type="button"
      >
        {this.formatDateButton()}
      </button>
    );
  }
}

BotonFecha.propTypes = {
  date: PropTypes.any,
  id: PropTypes.any,
  loadHours: PropTypes.any,
  diaSemana: PropTypes.any,
  diaNumero: PropTypes.any,
  mes: PropTypes.any,
  estiloClassname: PropTypes.any,
};

BotonFecha.defaultProps = {
  date: null,
  id: null,
  loadHours: null,
  diaSemana: null,
  diaNumero: null,
  mes: null,
  estiloClassname: null,
};
