import React, { useState } from "react";
import TamizarPacienteAgendaGral from "./TamizarPacienteAgendaGral";

export default (props) => {
  const [estadoLead, setEstadolead] = useState({
    nombre: "",
    apellidoP: "",
    apellidoM: "",
  });

  function enviarsalesforce() {}

  if (process.env.NODE_ENV !== "production") {
    console.tron.display({
      name: `[Diagnosticos] TamizarPacienteAgendaGralContainer`,
      value: props.history.location.state,
    });
  }
  const { gdlvCampaign } = props.history.location.state;
  return (
    <TamizarPacienteAgendaGral
      gdlvCampaign={gdlvCampaign}
      estadoLead={estadoLead}
      setEstadolead={setEstadolead}
    />
  );
  //pasar funcion para tomar los valore
  //Dentro de la peticion guardar variables para pasar a la siguiente cita
};
