import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class BotonHora extends Component {
  handleClick = () => {
    const {
      hora,
      selectTime,
    } = this.props

    selectTime(hora)
  }

  render() {
    const { className, hora } = this.props
    return (
      <div 
        className="horaBtn"
        onClick={this.handleClick}
        role="button"
        tabIndex="0"
      >
        <button
          className={className}
          type="button"
        >
          <p>
            {hora.texto}
          </p>
        </button>
      </div>
    )
  }
}

BotonHora.propTypes = {
  className: PropTypes.string,
  hora: PropTypes.object,
  datetime: PropTypes.func,
}

BotonHora.defaultProps = {
  className: '',
  hora: {},
  datetime: () => {},
}
