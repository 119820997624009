import React from "react";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import CancelFooterOptionContainer from "./CancelFooterOption/CancelFooterOptionContainer";
import GeneralFooterContainer from "./GeneralFooter/GeneralFooterContainer";
import styles from "./FooterContainerStyles.module.css";

const FooterContainer = ({ location }) => {
  return (
    <Container fluid className={`${styles.footerContainer} d-print-none`}>
      {location.state !== undefined && (
        <CancelFooterOptionContainer state={location.state.historialFlujo} />
      )}
      <GeneralFooterContainer />
    </Container>
  );
};

export default withRouter(FooterContainer);
