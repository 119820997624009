import React from "react";
import { Container, Row, Col } from "reactstrap";
import DoctoresCI from "data/DoctoresCI.json";
import sprite from "images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg";

import styles from "./BienvenidoStyles.module.css";

export default ({ handleChange, navega }) => {
  const renderPickListTipoBienvenida = () => {
    return (
      <Col xs={12} className={`${styles.colContainerListCI}`}>
        <h5>Nombre del doctor</h5>
        <select onChange={(e) => handleChange(e)}>
          {DoctoresCI.map((doctor) => (
            <option value={doctor.AccountNumber}>{doctor.nombre}</option>
          ))}
        </select>
      </Col>
    );
  };

  return (
    <Container fluid className={`${styles.containerBienvenido}`}>
      <Container className={`${styles.bienvenidoContainer}`}>
        <Row className={`${styles.rowContainer}`}>
          <Col xs={12} className={`${styles.colContantainer}`}>
            <Row className={`${styles.rowContainerBienvenido}`}>
              <Col xs={12} className={`${styles.colContainerTitleSubTitle}`}>
                <Row className={`${styles.rowContainerTitleSubtitle}`}>
                  <Col xs={12} className={`${styles.colContainerTitle}`}>
                    <p className={`${styles.colContainerTitle__title}`}>
                      Cirugia Inteligente
                    </p>
                  </Col>
                  <Col xs={12} className={`${styles.colContainerSubTitle}`}>
                    <p className={`${styles.colContainerSubTitle__subTitle}`}>
                      ¡agenda una cita!
                    </p>
                  </Col>
                  {renderPickListTipoBienvenida()}
                  <Col xs={12} className={`${styles.colContainerButton}`}>
                    <Row className={`${styles.rowContainerButtonsGDLV}`}>
                      <Col
                        className={`${styles.colContainerButtonGDLV}`}
                        xs={12}
                      >
                        {" "}
                        <button
                          className={`${styles.colContainerButton__button}`}
                          onClick={() => navega()}
                          type="button"
                        >
                          <Container fluid>
                            <Row>
                              <Col
                                xs={7}
                                className={`${styles.colContainerNombreBoton}`}
                              >
                                Agenda tu cita
                              </Col>
                              <Col
                                xs={4}
                                className={`${styles.colContainerSpanBoton}`}
                              >
                                <span>
                                  <svg className={`${styles.svg}`}>
                                    <use
                                      xlinkHref={`${sprite}#icon-arrow-right`}
                                    />
                                  </svg>
                                </span>
                              </Col>
                            </Row>
                          </Container>
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
