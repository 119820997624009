import Reactotron, { trackGlobalErrors } from 'reactotron-react-js'

console.tron = Reactotron

if (process.env.NODE_ENV !== 'production') {
  Reactotron
    .configure()
    .use(trackGlobalErrors())
    .connect()
  Reactotron.clear()
}
