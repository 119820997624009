function existeCampania(campania) {
  const campanias = require("../data/campaigns.json");
  const nombreCampanias = Object.keys(campanias);

  return nombreCampanias.includes(campania);
}

function vigenteCampania(campana) {
  const campanias = require("../data/campaigns.json");

  return campanias[campana].active;
}

export { existeCampania, vigenteCampania };
