import React from "react";
import axios from "axios";
import Confirmacion from "./Confirmacion";

const baseUrl =
  window.location.hostname === "citas.salauno.com.mx"
    ? "https://salauno-nodeserver.azurewebsites.net"
    : "https://salauno-nodeserver-testing.azurewebsites.net";

const ConfirmacionContainer = ({ history: { location }, history }) => {
  const { state, search } = location;
  console.log("stateConfirmacion:", state);

  const cancelar = async () => {
    const body = { idCita: state.cita.id };

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    // console.log("continuar body:", body);
    const response = await axios.put(
      baseUrl + "/acuity/cancelarCita",
      body,
      config
    );
    console.log("continuar response:", response);
    if (response.data.canceled) {
      const newHistory = {
        historialFlujo: {
          flujoIsActive: false,
          flujoStep: 0,
          flujo1: [],
          tipoConsulta: "",
        },
        sinPromocion: false,
        header: [],
        regresion: null,
        prioridad: null,
        para: {},
        diagnostico: {},
        promocion: null,
        clinica: {
          cambiarClinica: false,
        },
        reagendar: null,
        cita: {},
        calendarId: null,
        serviceId: null,
        tiempoAntesDeCita: null,
        infoPx: [],
        gdlvTipoConsulta: "",
        edadPromocion: null,
      };
      history.push("/cita/bienvenidaCI", newHistory);
    }
  };

  const imprimir = () => {
    window.print();
  };

  const inicio = () => {
    const newHistory = {
      historialFlujo: {
        flujoIsActive: false,
        flujoStep: 0,
        flujo1: [],
        tipoConsulta: "",
      },
      sinPromocion: false,
      header: [],
      regresion: null,
      prioridad: null,
      para: {},
      diagnostico: {},
      promocion: null,
      clinica: {
        cambiarClinica: false,
      },
      reagendar: null,
      cita: {},
      calendarId: null,
      serviceId: null,
      tiempoAntesDeCita: null,
      infoPx: [],
      gdlvTipoConsulta: "",
      edadPromocion: null,
    };
    history.push("/cita/bienvenidaCI", newHistory);
  };

  const reagendar = () => {
    const newHistory = {
      ...state,
    };
    history.push("/cita/horarioCI", newHistory);
  };

  return (
    <Confirmacion
      state={state}
      cancelar={cancelar}
      imprimir={imprimir}
      inicio={inicio}
      reagendar={reagendar}
    />
  );
};

export default ConfirmacionContainer;
