import React, { useCallback, useEffect, useState } from "react";
import { getParams } from "functions/GetUrlParams";
import axios from "axios";
import { PDFRecuperacion } from "./PDFRecuperacion";

const urlParams = getParams();
let sVFreciemcoaCardiaca = [];
let sVSpO2 = [];
let sVTensionArterial = [];
let sVTemperatura = [];
let sVGlucosa = [];
let sVFrecuenciaRespiratoria = [];



const baseUrl =
  window.location.hostname === "citas.salauno.com.mx"
    ? "https://salauno-nodeserver.azurewebsites.net/salesforce"
    : "https://salauno-nodeserver-testing.azurewebsites.net/salesforce";

export const PDFRecuperacionContainer = () => {
  const [dataSignosVitales, setDataSignosVitales] = useState([]);
  const [nota, setaNota] = useState({});
  const [medicamentos, setMedicamentos] = useState([]);
  const [isLogin, setIsLogin] = useState(true);

  const getSignosVitales = useCallback(async () => {
    const body = { 
      endpoint: "notaCirugiaRecuperacion",
      idCirugia: urlParams.cirugia,
    };

    axios
      .post(`${baseUrl}/pdfRecuperacion`, body)
      .then((response) => {
        if (response.data.StatusCode === "Done") {
          console.log("Estatus Done", response.data);
          let signosVitales = [];
          response.data.signos_vitales.map((signoVital) => {
            signosVitales.push(signoVital);
          });
          console.log("signosVitales:", signosVitales);
          // setDataSignosVitales(JSON.stringify(response.data.signos_vitales));
          setDataSignosVitales(signosVitales);
          setaNota(response.data.notaRecuperacion);
          setMedicamentos(response.data.medicamentos);

          SVParaGraficas(response.data.signos_vitales);

          if (signosVitales.length > 0) {
            setIsLogin(false);
          }
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });
  }, []);

        
     const SVParaGraficas = (arraySignosVitales) => {

          arraySignosVitales.forEach(sVital => {
            let valueFrecCard = {"value" : sVital.frecuenciaCardiaca, "fechaYHora": sVital.fechaYHoraToma };
            sVFreciemcoaCardiaca.push(valueFrecCard);
          });

          arraySignosVitales.forEach(sVital => {
            let spO2 = {"value" : sVital.spO2, "fechaYHora": sVital.fechaYHoraToma };
            sVSpO2.push(spO2);
          });

          arraySignosVitales.forEach(sVital => {
            let tensionArterialSplit = sVital.tensionArterial.split('/');
            let valueTensionArterial = {"value1" : tensionArterialSplit[0], "value2" : tensionArterialSplit[1], "fechaYHora": sVital.fechaYHoraToma };
            sVTensionArterial.push(valueTensionArterial);
          });

          arraySignosVitales.forEach(sVital => {
            let temperatura = {"value" : sVital.tempratura, "fechaYHora": sVital.fechaYHoraToma };
            sVTemperatura.push(temperatura);
          });

          arraySignosVitales.forEach(sVital => {
            let glucosa = {"value" : sVital.glucosa, "fechaYHora": sVital.fechaYHoraToma };
            sVGlucosa.push(glucosa);
          });

          arraySignosVitales.forEach(sVital => {
            let frecuenciaRespiratoria = {"value" : sVital.frecuenciaRespiratoria, "fechaYHora": sVital.fechaYHoraToma };
            sVFrecuenciaRespiratoria.push(frecuenciaRespiratoria);
          });


     } 
  
        useEffect(() => {
          getSignosVitales();
        }, []);






  return (
    <PDFRecuperacion 
      dataSignosVitales={dataSignosVitales}
      nota={nota}
      medicamentos={medicamentos}
      isLogin={isLogin}
      sVFreciemcoaCardiaca={sVFreciemcoaCardiaca}
      sVTemperatura={sVTemperatura}
      sVSpO2={sVSpO2}
      sVGlucosa={sVGlucosa}
      sVFrecuenciaRespiratoria={sVFrecuenciaRespiratoria}
      sVTensionArterial={sVTensionArterial}

    />
  );
};
