import React, { useState } from "react";
import PropTypes from "prop-types";

import Promociones from "./Promociones";

import { obtenPromocion, obtenPromocionConfigAliados } from "functions/Promociones";

import DiagnosticosData from "data/Diagnosticos.json";
import FlowsData from "data/flows.json";
import ConfigAliados from "data/ConfigAliados.json";

const PromoContainer = ({ location, history }) => {
  const [state] = useState({ toast: false });
  const [edad, setEdad] = useState(0);
  //const promoData = obtenPromocion(location.state.urlParams.promo);
  let promoData;

  let parametros = new URLSearchParams(window.location.search);
  let medioparams = parametros.get('medio');

  console.log("obtenPromocion(location.state.urlParams.promo): ",obtenPromocion(location.state.urlParams.promo)); 
  console.log("ConfigAliados[medioparams]: ",ConfigAliados[medioparams]);
  
    if (ConfigAliados[medioparams] !== undefined){
      console.log("existe en config aliados");
      promoData = obtenPromocionConfigAliados(medioparams);
    }
    else{
      console.log("no existe en config aliados");
      promoData = obtenPromocion(medioparams);
    }

  if (process.env.NODE_ENV !== "production") {
    console.tron.display({
      name: `[Promociones] History`,
      value: location.state,
      important: true,
    });
  }
  const handleEdad = (e) => {
    setEdad(e.target.value);
  };

  const goToPromo = (servicioSeleccionado) => {
    const { state } = location;
    let {
      historialFlujo,
      urlParams: { promo },
    } = state;
    if (promo !== "FIDELITY" || !promo !== "COCA-COLA") {
      historialFlujo.flujo1 = [
        "Selecciona tu clínica",
        "Elige la fecha y hora",
        "Confirma tus datos de contacto",
        "Confirmación de cita",
      ];
      const indexDiagnostic = location.state.diagnostico.id;
      const regresion = false;

      const header = [
        {
          id: 1,
          titulo: "Servicio",
          dato: promoData.titulo,
          ruta: "/cita/promocion",
          tarjetaID: DiagnosticosData[indexDiagnostic].nombreServicio,
        },
      ];
      promoData.idSeleccionado = servicioSeleccionado;
      const newHistory = {
        ...location.state,
        header,
        regresion,
        prioridad: DiagnosticosData[indexDiagnostic].prioridad,
        para: { paraQuien: 1 },
        diagnostico: DiagnosticosData[indexDiagnostic],
        promocion: promoData,
        urlParams: {
          ...location.state.urlParams,
          cmp: promoData.campaign[0],
        },
        historialFlujo,
        edadPromocion: parseInt(edad),
      };
      newHistory.diagnostico.idServicio = servicioSeleccionado;

      newHistory.diagnostico.routes = {
        ...FlowsData[DiagnosticosData[indexDiagnostic].routes],
      };

      history.push(
        DiagnosticosData[indexDiagnostic].routes[location.pathname] +
          history.location.search,
        newHistory
      );
    } else {
      if (edad >= 60) {
        historialFlujo.flujo1 = [
          "Selecciona tu clínica",
          "Elige la fecha y hora",
          "Confirma tus datos de contacto",
          "Confirmación de cita",
        ];
        const indexDiagnostic = location.state.diagnostico.id;
        const regresion = false;

        const header = [
          {
            id: 1,
            titulo: "Servicio",
            dato: promoData.titulo,
            ruta: "/cita/promocion",
            tarjetaID: DiagnosticosData[indexDiagnostic].nombreServicio,
          },
        ];
        promoData.idSeleccionado = servicioSeleccionado;
        const newHistory = {
          ...location.state,
          header,
          regresion,
          prioridad: DiagnosticosData[indexDiagnostic].prioridad,
          para: { paraQuien: 1 },
          diagnostico: DiagnosticosData[indexDiagnostic],
          promocion: promoData,
          urlParams: {
            ...location.state.urlParams,
            cmp: promoData.campaign[0],
          },
          historialFlujo,
        };
        newHistory.diagnostico.idServicio = servicioSeleccionado;

        newHistory.diagnostico.routes = {
          ...FlowsData[DiagnosticosData[indexDiagnostic].routes],
        };

        history.push(
          DiagnosticosData[indexDiagnostic].routes[location.pathname] +
            history.location.search,
          newHistory
        );
      }
    }
    // else {
    // 	historialFlujo.flujo1 = [
    // 		'Indica tus síntomas',
    // 		'Medio de contacto',
    // 		'Fecha y hora',
    // 		'Datos de contacto',
    // 		'Forma de pago',
    // 		'Confirmación de cita',
    // 	];
    // 	const indexDiagnostic = location.state.diagnostico.id;
    // 	const regresion = false;

    // 	const header = [
    // 		{
    // 			id: 1,
    // 			titulo: 'Servicio',
    // 			dato: promoData.titulo,
    // 			ruta: '/cita/promocion',
    // 			tarjetaID: DiagnosticosData[indexDiagnostic].nombreServicio,
    // 		},
    // 	];
    // 	const newHistory = {
    // 		...location.state,
    // 		header,
    // 		regresion,
    // 		prioridad: DiagnosticosData[12].prioridad,
    // 		para: { paraQuien: 1 },
    // 		diagnostico: { ...DiagnosticosData[12] },
    // 		promocion: null,
    // 		historialFlujo,
    // 	};
    // 	newHistory.diagnostico.routes = {
    // 		...FlowsData[DiagnosticosData[12].routes],
    // 	};
    // 	historialFlujo.tipoConsulta = 'remoto';
    // 	newHistory.urlParams.promo = null;
    // 	newHistory.EdadPaciente = edad;
    // 	history.push('/cita/orientacion', newHistory);
    // }
  };

  const backToHome = () => {
    const newHistory = {
      ...location.state,
      urlParams: {
        ...location.state.urlParams,
        promo: null,
      },
      sinPromo: true,
    };
    history.push(
      location.state.header[0].ruta + history.location.search,
      newHistory
    );
  };

  return (
    <Promociones
      goToPromo={goToPromo}
      backToHome={backToHome}
      state={state}
      promoData={promoData}
      history={history}
      edad={edad}
      handleEdad={handleEdad}
      location={location}
    />
  );
};

export default PromoContainer;

PromoContainer.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};

PromoContainer.defaultProps = {
  location: null,
  history: null,
};
