/* eslint-disable quotes */
// @flow
// ? Configurations
import "./config/ReactotronConfig";
import "bootstrap/dist/css/bootstrap.css";

// ? React libarys required
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { store } from "Store/store";
import Routes from "./config/Routes";
import * as serviceWorker from "./serviceWorker";

// Live

// ? TagManager configuration
let tagManagerArgs = {
  gtmId: "GTM-PSRRHG9",
  auth: "H997DI7Eb5CRY8ZaedRnCg",
  preview: "env-2",
};

if (window.location.hostname !== "citas.salauno.com.mx") {
  // QA
  tagManagerArgs.auth = "aQYS93oM_KIgS0JYrnSV6A";
  tagManagerArgs.preview = "env-152";

  // Dev
  // tagManagerArgs.auth = 'oApoxSwDDtASfKQ1r5aZeQ';
  // tagManagerArgs.preview = 'env-144';
}

TagManager.initialize(tagManagerArgs);

console.log("Agenda 2.0");

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
