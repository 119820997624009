import React from 'react';
import CreaLead from './CreaLead';

export default (props) => {
  if (process.env.NODE_ENV !== 'production') {
    console.tron.display({
      name: `[Diagnosticos] CreaLeadContainer`,
      value: props.history.location.state,
    });
  }
  const { gdlvCampaign } = props.history.location.state;
  return <CreaLead gdlvCampaign={gdlvCampaign} />;
};
