import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import PropTypes from "prop-types";

import styles from "./ComoLlego.module.css";

import comoLlegoAuto from "../../images/svg/elegirSucursal__auto.svg";
import comoLlegoMetro from "../../images/svg/elegirSucursal__metro.svg";
import comoLlegoMetrobus from "../../images/svg/elegirSucursal__metrobus.svg";

import clinicas from "../../data/clinica";

const TXT_DEFINIR = "Por definir";
const TXT_SELECT = "Primero elige tu clínica más cercana";

export default class ComoLlego extends Component {
  constructor(props) {
    super(props);

    let comoLlego = {
      Auto: TXT_DEFINIR,
      Metro: TXT_DEFINIR,
      Metrobus: TXT_DEFINIR,
    };
    let clinicAddress = TXT_SELECT;

    if (props.clinicKey !== null) {
      ({ comoLlego, clinicAddress } = clinicas[props.clinicKey]);
    }

    this.state = {
      clinicKey: props.clinicKey,
      clinicAddress,
      auto: comoLlego.Auto,
      transporteUno: comoLlego.Metro,
      transporteDos: comoLlego.Metrobus,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.clinicKey !== state.clinicKey) {
      const { comoLlego, ClinicAddress } = clinicas[props.clinicKey];
      return {
        clinicKey: props.clinicKey,
        clinicAddress: ClinicAddress,
        auto: comoLlego.Auto,
        transporteUno: comoLlego.Metro,
        transporteDos: comoLlego.Metrobus,
      };
    }
    return null;
  }

  render() {
    const { auto, transporteUno, transporteDos } = this.state;
    const { nombreClinica } = this.props;
    return (
      <Container fluid className={`${styles.comoLlegoContainer}`}>
        <Row className={`${styles.rowContainerNombreClinica}`}>
          <Col xs={12} className={`${styles.colContainerNombreClinica}`}>
            <p className={`${styles.colContainerNombreClinica__title}`}>
              {nombreClinica}
            </p>
            <div className={`${styles.colContainerNombreClinica__border}`}/>
          </Col>
        </Row>
        <Row className={`${styles.rowContainerComoLlegar}`}>
          <Col xs={12} className={`${styles.colContainerComoLlegar}`}>
            <p className={`${styles.colContainerComoLlegar__p}`}>
              ¿Cómo llegar a la clínica?
            </p>
          </Col>
        </Row>
        <Row className={`${styles.rowContainerMedioDeLlegada}`}>
          <Col xs={12} className={`${styles.colContainerMedioDeLlegada}`}>
            <Row className={`${styles.colContainerMedioDeLlegadaTransporte}`}>
              <Col
                xs={12}
                className={`${styles.colContainerMedioDeLlegadaTransporteTitle} d-flex`}
              >
                <img src={comoLlegoAuto} alt="Img_Auto"/>
                <p
                  className={`${styles.colContainerMedioDeLlegadaTransporteTitle__title}`}
                >
                  En Auto
                </p>
              </Col>
              <p
                className={`${styles.colContainerMedioDeLlegadaTransporte__p}`}
              >
                {auto}
              </p>
            </Row>
          </Col>
          <Col xs={12} className={`${styles.colContainerMedioDeLlegada}`}>
            <Row className={`${styles.colContainerMedioDeLlegadaTransporte}`}>
              <Col
                xs={12}
                className={`${styles.colContainerMedioDeLlegadaTransporteTitle} d-flex `}
              >
                <img src={comoLlegoMetro} alt="Img_Metro"/>
                <p
                  className={`${styles.colContainerMedioDeLlegadaTransporteTitle__title}`}
                >
                  En Metro
                </p>
              </Col>
              <p
                className={`${styles.colContainerMedioDeLlegadaTransporte__p}`}
              >
                {transporteUno}
              </p>
            </Row>
          </Col>
          <Col xs={12} className={`${styles.colContainerMedioDeLlegada}`}>
            <Row className={`${styles.colContainerMedioDeLlegadaTransporte}`}>
              <Col
                xs={12}
                className={`${styles.colContainerMedioDeLlegadaTransporteTitle} d-flex`}
              >
                <img src={comoLlegoMetrobus} alt="Img_Metrobus"/>
                <p
                  className={`${styles.colContainerMedioDeLlegadaTransporteTitle__title}`}
                >
                  En Metrobús
                </p>
              </Col>
              <p
                className={`${styles.colContainerMedioDeLlegadaTransporte__p}`}
              >
                {transporteDos}
              </p>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

ComoLlego.propTypes = {
  clinicKey: PropTypes.number,
  nombreClinica: PropTypes.string,
};

ComoLlego.defaultProps = {
  clinicKey: null,
};
