import React, { useState } from "react";
import { PulseLoader } from "react-spinners";
import { withRouter } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import FormaDePagoSelectorContainer from "./FormaDePagoSelector/FormaDePagoSelectorContainer";
import FormaDePagoContainer from "./FormaDePago/FormaDePagoContainer";
import FormaDePagoTarjetaContainer from "./FormaDePagoTarjeta/FormaDePagoTarjetaContainer";

import sprite from "images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg";

import styles from "./PagodeCitaStyles.module.css";

const PagoDeCita = ({ history, location }) => {
  const { state } = location;
  if (process.env.NODE_ENV !== "production") {
    console.tron.display({
      name: `[PagoDeCita] History`,
      value: state,
      important: true,
    });
  }
  const { id, price } = state.cita.response;
  const { infoPx } = state;
  const datosParaPago = {
    idCita: id,
    nombre: `${infoPx[0].paciente1} ${infoPx[0].apellidoP1} ${infoPx[0].apellidoM1}`,
    email: infoPx[2].email,
    telContact: infoPx[2].telefono,
    totalPagar: price,
  };
  const [tipoPago, setTipoPago] = useState("");
  const [pagoOrientacion, setPagoOrientacion] = useState(false);
  const [pagoOrientacionFormaDePago, setpagoOrientacionFormaDePago] = useState(
    false
  );
  const [peticion, setPeticion] = useState("Hacer peticion");
  const [descargarComprobante, setDescargarComprobante] = useState(false);
  const getFormaDePago = (tipo) => {
    setTipoPago(tipo);
  };
  const changePagoOrientacion = () => {
    setPagoOrientacion(true);
  };
  const changePagoOrientacionFormaDePago = () => {
    setpagoOrientacionFormaDePago(true);
  };
  const getPeticion = (peticionEntrante) => {
    setPeticion(peticionEntrante);
  };
  const changeDescargarComprobante = () => {
    setDescargarComprobante(true);
  };
  
  return (
    <Container fluid className={`${styles.container}`}>
      <Container className={`${styles.containerFormasDePago} py-5`}>
        <Row className={styles.rowContainer}>
          <Col lg={4} className={styles.colFormaDePagoSelector}>
            <FormaDePagoSelectorContainer getFormaDePago={getFormaDePago} />
          </Col>

          {tipoPago === "spei" && (
            <Col
              lg={8}
              className={`${styles.colFormaDePagTarjeta} d-flex justify-content-center`}
            >
              <Row className={`${styles.rowContainerFormaDePago}`}>
                <Col className={`${styles.colContainerFormaDePago}`}>
                  <FormaDePagoContainer
                    tipo={tipoPago}
                    datosParaPago={datosParaPago}
                    state={state}
                    history={history}
                    location={location}
                    getPeticion={getPeticion}
                    pagoOrientacionFormaDePago={pagoOrientacionFormaDePago}
                    descargarComprobante={descargarComprobante}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {tipoPago === "oxxo_cash" && (
            <Col
              lg={8}
              className={`${styles.colFormaDePagTarjeta} d-flex justify-content-center`}
            >
              <Row className={`${styles.rowContainerFormaDePago}`}>
                <Col className={`${styles.colContainerFormaDePago}`}>
                  <FormaDePagoContainer
                    tipo={tipoPago}
                    datosParaPago={datosParaPago}
                    state={state}
                    history={history}
                    location={location}
                    getPeticion={getPeticion}
                    pagoOrientacionFormaDePago={pagoOrientacionFormaDePago}
                    descargarComprobante={descargarComprobante}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {tipoPago === "card" && (
            <Col
              lg={8}
              className={`${styles.colContainerFormaDePagoTarjeta} d-flex justify-content-center`}
            >
              <Row className={`${styles.rowContainerFormaDePagoTarjeta}`}>
                <Col className={`${styles.colFormaDePagoTarjeta} no-gutters`}>
                  <FormaDePagoTarjetaContainer
                    datosParaPago={datosParaPago}
                    state={state}
                    history={history}
                    location={location}
                    pagoOrientacion={pagoOrientacion}
                    getPeticion={getPeticion}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row className={`${styles.rowContainerPriceButton} py-5`}>
          <Col lg={12} sm={12} className={`${styles.colContainerButton}`}>
            <Row className={`${styles.rowContainerButon}`}>
              <Col xs={12} md={6} className={`${styles.colContainerPrecio}`}>
                <label
                  className={`${styles.colContainerPrecio__label}`}
                >{`$ ${datosParaPago.totalPagar} pesos`}</label>
              </Col>
              <Col xs={12} md={6} className={`${styles.colContainerPagoCitaButton}`}>
                {peticion === "Hacer peticion" && (
                  <button
                    onClick={
                      tipoPago === "card"
                        ? changePagoOrientacion
                        : changePagoOrientacionFormaDePago
                    }
                    className={`${styles.colContainerButton__button}`}
                    form={tipoPago === "card" ? "card-form" : "speiOxxoForm"}
                  >
                    <Container
                      fluid
                      className={`${styles.buttonContainerParams}`}
                    >
                      <Row className={`${styles.rowContainerParams}`}>
                        <Col
                          xs={9}
                          className={`${styles.colContainerNombreBoton}`}
                        >
                          PAGAR ORIENTACIÓN
                        </Col>
                        <Col
                          xs={2}
                          className={`${styles.colContainerSpanBoton}`}
                        >
                          <span>
                            <svg className={`${styles.svg}`}>
                              <use xlinkHref={`${sprite}#icon-arrow-right`} />
                            </svg>
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </button>
                )}
                {peticion === "Haciendo peticion" && (
                  <PulseLoader
                    sizeUnit="px"
                    size={15}
                    margin="4px"
                    color="#00748c"
                  />
                )}
                {peticion === "Peticion confirmada" && (
                  <button
                    type="button"
                    className={`${styles.colContainerButton__button}`}
                    onClick={changeDescargarComprobante}
                  >
                    CONTINUAR
                  </button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default withRouter(PagoDeCita);
