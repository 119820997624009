import React from "react";
import { Container, Row, Col } from "reactstrap";
import sprite from "../../images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg";
import styles from "./LlamadaVideoLlamadaStyles.module.css";

import { TarjetasHorario } from "../Tarjetas";

export default ({ contacto, setContacto, irSiguientePagina }) => {
  return (
    <Container fluid className={`${styles.container}`}>
      <Container
        className={`${styles.containerLlamadaVideoLlamada} d-flex align-items-center justify-content-center`}
      >
        <Row className={`${styles.rowContainerLlamadaVideoLlamada}`}>
          <Col
            xs={12}
            className={`${styles.colContainerTitle} d-flex justify-content-center align-items-center`}
          >
            <p className={`${styles.colContainerTitle__title}`}>
              Selecciona el tipo de llamada que deseas recibir de tu doctor de
              los ojos para tu orientación medica oftalmológica:
            </p>
          </Col>
          <Col
            xs={12}
            className={`${styles.colContainerLlamadaVideoLlamada} d-flex justify-content-center align-items-center py-2`}
          >
            <TarjetasHorario
              cargar={setContacto}
              horario={contacto}
              type="Contacto"
              opcUno="Llamada"
              opcDos="Videollamada"
            />
          </Col>
        </Row>
        <Row className={`${styles.rowContainerButtons}`}>
          <Col
            xs={12}
            className={`d-flex justify-content-center align-items-center`}
          >
            <button
              className={`${styles.colContainerButton__button}`}
              onClick={irSiguientePagina}
            >
              <Container fluid>
                <Row>
                  <Col xs={8} className={`${styles.colContainerNombreBoton}`}>
                    CONTINUAR
                  </Col>
                  <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                    <span className={`${styles.colContainerButton__span}`}>
                      <svg className={`${styles.colContainerButton__svg}`}>
                        <use xlinkHref={`${sprite}#icon-arrow-right`} />
                      </svg>
                    </span>
                  </Col>
                </Row>
              </Container>
            </button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
