export function existePromocion(promocion) {
  const promociones = require("data/promociones.json");
  const nombrePromociones = Object.keys(promociones);

  return nombrePromociones.includes(promocion);
}

export function existePromocionConfigAliados(promocion) {
  const promociones = require("data/ConfigAliados.json");
  const nombrePromociones = Object.keys(promociones);

  return nombrePromociones.includes(promocion);
}

export function vigentePromocion(promocion, today) {
  const promociones = require("data/promociones.json");

  if (process.env.NODE_ENV !== 'production') {
    console.tron.display({
      name: `[Promociones] vigentePromocion`,
      value: {
        resultado: today.isBefore(promociones[promocion].vigencia),
        promocion,
        vigencia: promociones[promocion].vigencia,
      },
    })
  }
  
  return today.isBefore(promociones[promocion].vigencia);
}

export function vigentePromocionConfigAliados(promocion, today) {
  const promociones = require("data/ConfigAliados.json");

  if (process.env.NODE_ENV !== 'production') {
    console.tron.display({
      name: `[Promociones] vigentePromocion`,
      value: {
        resultado: today.isBefore(promociones[promocion].vigencia),
        promocion,
        vigencia: promociones[promocion].vigencia,
      },
    })
  }
  
  return today.isBefore(promociones[promocion].vigencia);
}

export function obtenPromocion(promocion) {
  const promociones = require("data/promociones.json");

  return promociones[promocion];
}

export function obtenPromocionConfigAliados(promocion) {
  const promociones = require("data/ConfigAliados.json");

  return promociones[promocion];
}
