import React from "react";
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import styles from "./ConfirmacionStyles.module.css";

const Confirmacion = ({ state, cancelar, imprimir, inicio, reagendar }) => {
  return (
    <Container fluid className={styles.containerConfirmacion}>
      <Row className={styles.rowDatosConfirmacion}>
        <Col xs={12} md={6} className={styles.colDatosConfirmacion_Cita}>
          <Container className={styles.containerCita}>
            <div className={styles.divContainerTitle}>
              <Row className={styles.rowDatosCitas}>
                <Col className={styles.colTitle}>
                  <p>Confirmacion de cita</p>
                </Col>
              </Row>
            </div>
            <div className={styles.divContainerDatos}>
              <Row className={styles.rowContainerItemColumns}>
                <Col className={styles.colContainerItem}>
                  <Row className={styles.rowContainerItem}>
                    <Col xs={5} className={styles.colItem_name}>
                      <p>Categoria:</p>
                    </Col>
                    <Col xs={7} className={styles.colItem_value}>
                      <p>{state.cita.category}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={styles.rowContainerItemColumns}>
                <Col className={styles.colContainerItem}>
                  <Row className={styles.rowContainerItem}>
                    <Col xs={5} className={styles.colItem_name}>
                      <p>Fecha programada:</p>
                    </Col>
                    <Col xs={7} className={styles.colItem_value}>
                      <p>{state.cita.date}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={styles.rowContainerItemColumns}>
                <Col className={styles.colContainerItem}>
                  <Row className={styles.rowContainerItem}>
                    <Col xs={5} className={styles.colItem_name}>
                      <p>Inicio programado:</p>
                    </Col>
                    <Col xs={7} className={styles.colItem_value}>
                      <p>{state.cita.time}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={styles.rowContainerItemColumns}>
                <Col className={styles.colContainerItem}>
                  <Row className={styles.rowContainerItem}>
                    <Col xs={5} className={styles.colItem_name}>
                      <p>Termino programado:</p>
                    </Col>
                    <Col xs={7} className={styles.colItem_value}>
                      <p>{state.cita.endTime}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={styles.rowContainerItemColumns}>
                <Col className={styles.colContainerItem}>
                  <Row className={styles.rowContainerItem}>
                    <Col xs={5} className={styles.colItem_name}>
                      <p>Nombre:</p>
                    </Col>
                    <Col xs={7} className={styles.colItem_value}>
                      <p>{state.cita.firstName}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={styles.rowContainerItemColumns}>
                <Col className={styles.colContainerItem}>
                  <Row className={styles.rowContainerItem}>
                    <Col xs={5} className={styles.colItem_name}>
                      <p>Apellidos:</p>
                    </Col>
                    <Col xs={7} className={styles.colItem_value}>
                      <p>{state.cita.lastName}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={styles.rowContainerItemColumns}>
                <Col className={styles.colContainerItem}>
                  <Row className={styles.rowContainerItem}>
                    <Col xs={5} className={styles.colItem_name}>
                      <p>Telefono:</p>
                    </Col>
                    <Col xs={7} className={styles.colItem_value}>
                      <p>{state.cita.phone}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={styles.rowContainerItemColumns}>
                <Col className={styles.colContainerItem}>
                  <Row className={styles.rowContainerItem}>
                    <Col xs={5} className={styles.colItem_name}>
                      <p>Correo:</p>
                    </Col>
                    <Col xs={7} className={styles.colItem_value}>
                      <p>{state.cita.email}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={styles.rowContainerItemColumns}>
                <Col className={styles.colContainerItem}>
                  <Row className={styles.rowContainerItem}>
                    <Col xs={5} className={styles.colItem_name}>
                      <p>Id de seguimiento:</p>
                    </Col>
                    <Col xs={7} className={styles.colItem_value}>
                      <p>{state.cita.id}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
        </Col>
        <Col xs={12} md={6} className={styles.colDatosConfirmacion_Cx}>
          <Container className={styles.containerCita}>
            <div className={styles.divContainerTitle}>
              <Row className={styles.rowDatosCx}>
                <Col className={styles.colTitle}>
                  <p>Confirmacion de {state.CategoriaCI}</p>
                </Col>
              </Row>
            </div>
            <div className={styles.divContainerDatos}>
              <Row className={styles.rowContainerItemColumns}>
                <Col className={styles.colContainerItem}>
                  <Row className={styles.rowContainerItem}>
                    <Col xs={5} className={styles.colItem_name}>
                      <p>Categoria:</p>
                    </Col>
                    <Col xs={7} className={styles.colItem_value}>
                      <p>{state.CategoriaCI}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {state.formState.ojoOperar !== "" ? (
                <Row className={styles.rowContainerItemColumns}>
                  <Col className={styles.colContainerItem}>
                    <Row className={styles.rowContainerItem}>
                      <Col xs={5} className={styles.colItem_name}>
                        <p>Ojo seleccionado:</p>
                      </Col>
                      <Col xs={7} className={styles.colItem_value}>
                        <p>{state.formState.ojoOperar}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null}
              {state.formState.tipoAnestecia !== "" ? (
                <Row className={styles.rowContainerItemColumns}>
                  <Col className={styles.colContainerItem}>
                    <Row className={styles.rowContainerItem}>
                      <Col xs={5} className={styles.colItem_name}>
                        <p>Anestecia:</p>
                      </Col>
                      <Col xs={7} className={styles.colItem_value}>
                        <p>{state.formState.tipoAnestecia}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null}
              {state.formState.equipo !== "" ? (
                <Row className={styles.rowContainerItemColumns}>
                  <Col className={styles.colContainerItem}>
                    <Row className={styles.rowContainerItem}>
                      <Col xs={5} className={styles.colItem_name}>
                        <p>Equipo:</p>
                      </Col>
                      <Col xs={7} className={styles.colItem_value}>
                        <p>{state.formState.equipo}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null}
              {state.formState.tipoTecnica !== "" ? (
                <Row className={styles.rowContainerItemColumns}>
                  <Col className={styles.colContainerItem}>
                    <Row className={styles.rowContainerItem}>
                      <Col xs={5} className={styles.colItem_name}>
                        <p>Técnica:</p>
                      </Col>
                      <Col xs={7} className={styles.colItem_value}>
                        <p>{state.formState.tipoTecnica}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null}
              {state.formState.requiereCalLio !== "" ? (
                <Row className={styles.rowContainerItemColumns}>
                  <Col className={styles.colContainerItem}>
                    <Row className={styles.rowContainerItem}>
                      <Col xs={5} className={styles.colItem_name}>
                        <p>Requiere Calculo de LIO:</p>
                      </Col>
                      <Col xs={7} className={styles.colItem_value}>
                        <p>{state.formState.requiereCalLio}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null}
              {state.formState.modeloLio !== "" ? (
                <Row className={styles.rowContainerItemColumns}>
                  <Col className={styles.colContainerItem}>
                    <Row className={styles.rowContainerItem}>
                      <Col xs={5} className={styles.colItem_name}>
                        <p>Modelo de LIO:</p>
                      </Col>
                      <Col xs={7} className={styles.colItem_value}>
                        <p>{state.formState.modeloLio}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null}
              {state.formState.poderLente !== "" ? (
                <Row className={styles.rowContainerItemColumns}>
                  <Col className={styles.colContainerItem}>
                    <Row className={styles.rowContainerItem}>
                      <Col xs={5} className={styles.colItem_name}>
                        <p>Poder de lente:</p>
                      </Col>
                      <Col xs={7} className={styles.colItem_value}>
                        <p>{state.formState.poderLente}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null}
              {state.formState.nombrePaciente !== "" ? (
                <Row className={styles.rowContainerItemColumns}>
                  <Col className={styles.colContainerItem}>
                    <Row className={styles.rowContainerItem}>
                      <Col xs={5} className={styles.colItem_name}>
                        <p>Nombre del paciente:</p>
                      </Col>
                      <Col xs={7} className={styles.colItem_value}>
                        <p>{state.formState.nombrePaciente}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null}
              {state.formState.insumos !== "" ? (
                <Row className={styles.rowContainerItemColumns}>
                  <Col className={styles.colContainerItem}>
                    <Row className={styles.rowContainerItem}>
                      <Col xs={5} className={styles.colItem_name}>
                        <p>Insumos:</p>
                      </Col>
                      <Col xs={7} className={styles.colItem_value}>
                        <p>{state.formState.insumos}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null}
              {state.formState.comentarios !== "" ? (
                <Row className={styles.rowContainerItemColumns}>
                  <Col className={styles.colContainerItem}>
                    <Row className={styles.rowContainerItem}>
                      <Col xs={5} className={styles.colItem_name}>
                        <p>Comentarios:</p>
                      </Col>
                      <Col xs={7} className={styles.colItem_value}>
                        <p>{state.formState.comentarios}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null}
            </div>
          </Container>
        </Col>
      </Row>
      <Row className={styles.rowButtons}>
        <Col xs={3} className={styles.colButtonCancelar}>
          <button
            type="button"
            className={styles.buttonCancelar}
            onClick={() => cancelar()}
          >
            Cancelar cita
          </button>
        </Col>
        <Col xs={3} className={styles.colButtonCancelar}>
          <button
            type="button"
            className={styles.buttonCancelar}
            onClick={() => reagendar()}
          >
            Re agendar
          </button>
        </Col>
        <Col xs={3} className={styles.colButtonCancelar}>
          <button
            type="button"
            className={styles.buttonCancelar}
            onClick={() => inicio()}
          >
            Ir al inicio
          </button>
        </Col>
        <Col xs={3} className={styles.colButtonImprimir}>
          <button
            type="button"
            className={styles.buttonImprimir}
            onClick={() => imprimir()}
          >
            Imprimir
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default Confirmacion;
