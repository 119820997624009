import React from "react";
import { Container, Row, Col } from "reactstrap";

import fb from "../../../images/svg/footer__facebook.svg";
import tw from "../../../images/svg/footer__twitter.svg";
import ig from "../../../images/svg/footer__ig.svg";
import le from "../../../images/svg/footer__linkedin.svg";

import styles from "./GeneralFooterStyles.module.css";

export default () => {
  return (
    <Container fluid className={`${styles.containerGeneralFooter}`}>
      <Row className={`${styles.rowGeneralFooter}`}>
        <Col
          xs={12}
          sm={9}
          className={`${styles.colContainerTitle} d-flex align-items-center justify-content-center`}
        >
          <p className={`${styles.colContainerTitle__title}`}>
            &copy; 2024 SALAUNO TU DOCTOR DE LOS OJOS. DERECHOS RESERVADOS
          </p>
        </Col>
        <Col
          xs={3}
          className={`${styles.colContainerIcons}  d-flex align-items-center justify-content-center`}
        >
          <Row>
            <Col xs={3} className={`${styles.colContainerIcons__icons}`}>
              <a href="https://twitter.com/salauno">
                <img
                  className={`${styles.colContainerIcons__svg}`}
                  src={tw}
                  alt="twitter icon"
                />
              </a>
            </Col>
            <Col xs={3} className={`${styles.colContainerIcons__icons}`}>
              <a href="https://www.instagram.com/salauno.mx">
                <img
                  className={`${styles.colContainerIcons__svg}`}
                  src={ig}
                  alt="ig icon"
                />
              </a>
            </Col>
            <Col xs={3} className={`${styles.colContainerIcons__icons}`}>
              <a href="https://www.facebook.com/salauno.mx">
                <img
                  className={`${styles.colContainerIcons__svg}`}
                  src={fb}
                  alt="facebook icon"
                />
              </a>
            </Col>
            <Col xs={3} className={`${styles.colContainerIcons__icons}`}>
              <a href="hhttps://www.linkedin.com/company/salauno/">
                <img
                  className={`${styles.colContainerIcons__svg}`}
                  src={le}
                  alt="linkedin icon"
                />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
