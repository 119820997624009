import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import GeneralHeaderContainer from './GeneralHeader/GeneralHeaderContainer';
// import HeaderPacienteExistenteContainer from "./HeaderPacienteExistente/HeaderPacienteExistenteContainer";
import FlujoHistorialContainer from './FlujoHistorial/FlujoHistorialContainer';
import styles from './HeaderContainerStyles.module.css';

const HeaderContainer = ({ history, location }) => {
  return (
    <Container fluid className={`${styles.headerContainer} d-print-none`}>
      <GeneralHeaderContainer location={location} history={history} />
      {/* <HeaderPacienteExistenteContainer /> */}
      {location.state !== undefined && (
        <FlujoHistorialContainer
          state={location.state.historialFlujo}
          history={history}
        />
      )}
    </Container>
  );
};

export default withRouter(HeaderContainer);
