import React, { useState } from "react";
import PropTypes from "prop-types";

import Promo2X1 from "./Promo2X1";

import DiagnosticosData from "data/Diagnosticos.json";
import FlowsData from "data/flows.json";

const Promo2X1Container = ({ location, history }) => {
  const [state] = useState({ toast: false });
  if (process.env.NODE_ENV !== "production") {
    console.tron.display({
      name: `[Promo 2X1] History`,
      value: location.state,
      important: true,
    });
  }

  const goToPromo = () => {
    const indexDiagnostic = location.state.diagnostico.id;
    const regresion = false;
    //COMBAK Corregir la parte del header
    const header = [
      {
        id: 1,
        titulo: "Servicio",
        dato: "Promoción 2x1",
        ruta: "/cita2X1",
        tarjetaID: DiagnosticosData[indexDiagnostic].nombreServicio,
      },
    ];
    const newHistory = {
      ...location.state,
      header,
      regresion,
      prioridad: DiagnosticosData[indexDiagnostic].prioridad,
      para: { paraQuien: 3 },
      diagnostico: DiagnosticosData[indexDiagnostic],
    };

    newHistory.diagnostico.routes = {
      ...FlowsData[DiagnosticosData[indexDiagnostic].routes],
    };

    history.push(
      DiagnosticosData[indexDiagnostic].routes[location.pathname] +
        history.location.search,
      newHistory
    );
  };

  const backToHome = () => {
    const newHistory = {
      ...location.state,
      ignorarPromocion: true,
      urlParams: {
        ...location.state.urlParams,
        promo: null,
      },
    };
    history.push(
      location.state.header[0].ruta + history.location.search,
      newHistory
    );
  };

  return (
    <Promo2X1 goToPromo={goToPromo} backToHome={backToHome} state={state} />
  );
};

export default Promo2X1Container;

Promo2X1Container.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};

Promo2X1Container.defaultProps = {
  location: null,
  history: null,
};
