import React, { useState } from "react";
import classNames from "classnames";

import styles from "./BotonGeneral.module.css";

export default (props) => {
  const [selected, setSelected] = useState(false);

  const getClassNames = () => {
    const { activo, estiloBackground } = props;
    return classNames({
      [styles.BotonGeneralActivoRedondo]: activo && !selected,
      [styles.background_azul]:
        activo && !selected && estiloBackground === "azul",
      [styles.background_amarillo]: activo && estiloBackground === "",
      [styles.BotonGeneralInactivoRedondo]: activo === false,
      [styles.BotonGeneralSeleccionadoRedondo]: activo && selected,
    });
  };

  const manejarClickBotonGeneral = () => {
    const { manejarClick } = props;
    setSelected(true);
    manejarClick();
  };
  const { existe, nombreBoton, activo, id } = props;
  if (existe) {
    return (
      <button
        onClick={activo ? () => manejarClickBotonGeneral() : null}
        className={getClassNames()}
        type="button"
        id={id}
      >
        {nombreBoton}
      </button>
    );
  }

  return null;
};
