import React from "react";
import { Container, Row, Col } from "reactstrap";
import martin from "images/png/Martin_trabajando.png";
import styles from "./TrabajandoStyles.module.css";

export default () => {
  return (
    <Container fluid className={`${styles.container} d-flex justify-content-center align-items-center`}>
      <Col>
        <Row className={`d-flex justify-content-center align-items-center`} >
          <Col xs={8} md={5} xl={3}>
            <img
              src={martin}
              alt="Martín Trabajando"
              className={`img-fluid`}
            />
          </Col>
        </Row>
        <Row className={`d-flex justify-content-center align-items-center`} >
          <Col xs={12}>
            <p className={`${styles.conInfo__p}`}>
              Estamos trabajando para darte
              <br />
              una mejor experiencia
            </p>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};
