/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable prefer-template */
/* eslint-disable quotes */
/* eslint-disable arrow-body-style */
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import SignosVitales from "./SignosVitales";
import { getParams } from "functions/GetUrlParams";

import EXEReports from "data/EXEReports.json";

const urlParams = getParams();

const baseUrl =
  window.location.hostname === "citas.salauno.com.mx"
    ? "https://salauno-nodeserver.azurewebsites.net/salesforce"
    : "https://salauno-nodeserver-testing.azurewebsites.net/salesforce";
// const baseUrl =
//   "https://salauno-nodeserver-testing.azurewebsites.net/salesforce";
const SignosVitalesContainer = () => {
  const [dataSignosVitales, setDataSignosVitales] = useState([]);
  const [labels, setLabels] = useState([]);
  const [handleSelect, setHanldeSelect] = useState("temperatura");

  useEffect(() => {
    if (!!urlParams.signoVital) {
      setHanldeSelect(urlParams.signoVital);
    } else {
      setHanldeSelect("temperatura");
    }
  }, []);

  const onHandleSelect = (e) => {
    console.log("event:", e.target.value);
    setHanldeSelect(e.target.value);
  };

  const getSignosVitales = useCallback(async () => {
    const body = {
      action: "getSignosVitales",
      cxName: urlParams.cirugia,
    };

    axios
      .post(`${baseUrl}/signosVitales`, body)
      .then((response) => {
        if (response.data.StatusCode === 200) {
          console.log("signos vitales:", response.data.signosVitales);  
          setDataSignosVitales(response.data.signosVitales);
          const dataLabels = [];
          response.data.signosVitales.map((signoVital) => {
            if (signoVital.fechaHoraToma) {
              const fechaHoraTomaRecortada = signoVital.fechaHoraToma.substring(
                0,
                16
              );
              dataLabels.push(fechaHoraTomaRecortada);
            }
          });
          // console.log("dataLabels:", dataLabels);
          setLabels(dataLabels);
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });
  }, []);

  useEffect(() => {
    getSignosVitales();
  }, []);

  return (
    <SignosVitales
      dataSignosVitales={dataSignosVitales}
      labels={labels}
      onHandleSelect={onHandleSelect}
      handleSelect={handleSelect}
    />
  );
};

export default SignosVitalesContainer;
