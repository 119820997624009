import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import styles from "../css/CanceladaStyles.module.css";

import { getParams } from "functions/GetUrlParams";

import eyeIcon from "../images/svg/cancelada__iconeye.svg";
import sprite from "../images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg";

const TXT_CANCEL_OMO = "¡Tu orientación en línea ya fue cancelada!";
const TXT_CANCEL_PRESENCIAL = "!Tu cita ya fue cancelada!";

const urlParams = getParams();

let newHistory = {
  historialFlujo: {
    flujoIsActive: false,
    flujoStep: 0,
    flujo1: [],
    tipoConsulta: "",
  },
  urlParams,
  sinPromocion: false,
  header: [],
  regresion: null,
  prioridad: null,
  para: {},
  diagnostico: {},
  promocion: null,
  clinica: {
    cambiarClinica: false,
  },
  reagendar: null,
  cita: {},
  calendarId: null,
  serviceId: null,
  tiempoAntesDeCita: null,
  infoPx: [],
  // gdlvCampaign: '',
  gdlvTipoConsulta: "",
  edadPromocion: null,
};

export default class Cancelada extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };

    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[Cancelada] History`,
        value: props.location.state,
        important: true,
      });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  manejarClick = () => {
    // if (process.env.NODE_ENV !== 'production') { console.tron.log(`[Cancelada] Manejar Click`) }
    window.location.replace("tel://554163-6969");
  };
  regresarInicio = () => {
    const { history } = this.props;
    if (urlParams.medio === "GDLV-P") {
      newHistory.gdlvCampaign=history.location.state.gdlvCampaign;
      newHistory.nombreGuardian=history.location.state.nombreGuardian;
      console.log(newHistory.gdlvCampaign);
      history.push({
        pathname: '/cita/bienvenida',
        search: '?medio=GDLV-P',
        state:newHistory
      })
    }else{ 
    history.push("/")
    }
  };

  render() {
    const { location } = this.props;
    const { state } = location;
    const { historialFlujo } = state;
    const { tipoConsulta } = historialFlujo;

    return (
      <Container fluid className={`${styles.containerCancelar}`}>
        <Container className={`${styles.cancelarContainer}`}>
          <Row className={`${styles.rowContainer}`}>
            <Col xs={12} className={`${styles.colContainer}`}>
              <Row className={`${styles.rowInfoContainer}`}>
                <Col
                  xs={12}
                  className={`${styles.colContainerIcon} d-flex justify-content-center align-items-center`}
                >
                  <img src={eyeIcon} alt="icon_eye"/>
                </Col>
                <Col
                  xs={12}
                  className={`${styles.colContainerTitle} d-flex justify-content-center align-items-center`}
                >
                  <p className={`${styles.colContainerTitle__title}`}>
                    {tipoConsulta === "remoto"
                      ? TXT_CANCEL_OMO
                      : TXT_CANCEL_PRESENCIAL}
                  </p>
                </Col>
                <Col xs={12} className={`${styles.colContainerSubTitle}`}>
                  <p
                    className={`${styles.colContainerSubTitle__subtitle} d-flex justify-content-center align-items-center`}
                  >
                    Deseamos poder atenderte pronto y dar solución a tus
                    problemas de la vista.
                  </p>
                </Col>
                <Col
                  xs={12}
                  className={`${styles.colContainerButton} d-flex justify-content-center`}
                >
                  <Row className={`${styles.rowContainerButton}`}>
                    <Col
                      xs={12}
                      className={`${styles.colContainerButtonAgendar}`}
                    >
                      <button
                        className={`${styles.colContainerButton__button}`}
                        onClick={this.regresarInicio}
                      >
                        <Container
                          fluid
                          className={`${styles.buttonContainerParams}`}
                        >
                          <Row className={`${styles.rowContainerParams}`}>
                            <Col
                              xs={8}
                              className={`${styles.colContainerNombreBoton}`}
                            >
                              AGENDAR NUEVA CITA
                            </Col>
                            <Col
                              xs={4}
                              className={`${styles.colContainerSpanBoton}`}
                            >
                              <span
                                className={`${styles.colContainerButton__span}`}
                              >
                                <svg
                                  className={`${styles.colContainerButton__svg}`}
                                >
                                  <use
                                    xlinkHref={`${sprite}#icon-arrow-right`}
                                  />
                                </svg>
                              </span>
                            </Col>
                          </Row>
                        </Container>
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}
