import React from "react";
import FlujoHistorial from "./FlujoHistorial";

export default ({ state, history }) => {
  const regresarAlInicio = () => {
    history.push("/");
  };
  return (
    <FlujoHistorial
      state={state}
      history={history.location.pathname}
      regresarAlInicio={regresarAlInicio}
    />
  );
};
