/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import { getParams } from "functions/GetUrlParams";

import DoctoresCI from "data/DoctoresCI.json";

import Bienvenido from "./BienvenidoCI";

const urlParams = getParams();

const newHistory = {
  historialFlujo: {
    flujoIsActive: false,
    flujoStep: 0,
    flujo1: [],
    tipoConsulta: "",
  },
  urlParams,
  sinPromocion: false,
  header: [],
  regresion: null,
  prioridad: null,
  para: {},
  diagnostico: {},
  promocion: null,
  clinica: {
    cambiarClinica: false,
  },
  reagendar: null,
  cita: {},
  calendarId: null,
  serviceId: null,
  tiempoAntesDeCita: null,
  infoPx: [],
  gdlvTipoConsulta: "",
  edadPromocion: null,
};

// eslint-disable-next-line react/prop-types
const BienvenidoContainer = ({ history }) => {
  const [accountNumber, setAccountNumber] = React.useState(null);

  const navega = () => {
    newHistory.doctor = accountNumber;

    // eslint-disable-next-line react/prop-types
    history.push(`/cita/categoriasCI${history.location.search}`, newHistory);
  };

  const handleChange = (event) => {
    const doctor = DoctoresCI.find((element) => {
      // console.log("element:", element.AccountNumber);
      // console.log("event:", event.target.value);
      if (element.AccountNumber === event.target.value) {
        return element;
      }
    });
    console.log("doctor:", doctor);
    setAccountNumber(doctor);
  };

  const getArrayByTipoBienvenida = () => {
    setAccountNumber(DoctoresCI[0]);
  };

  useEffect(() => {
    getArrayByTipoBienvenida();
  }, []);

  return <Bienvenido handleChange={handleChange} navega={navega} />;
};

export default withRouter(BienvenidoContainer);
