import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { BotonHora } from '../Botones'

import stylesBoton from '../Botones/Botones.module.css'
import styles from './Tarjetas.module.css'

const martinSinCitas = require('../../images/svg/NoPromo.svg')

export default class TarjetasHora extends Component {
  constructor(props) {
    super(props)

    const { horas } = props

    this.state = {
      horas,
      keyButton: null,
      horasTextuales: [],
    }
  }

  componentDidMount() {
    const { horas } = this.state

    this.crearHorasTextuales(horas)
  }

  componentDidUpdate() {
    const { horas } = this.props
    const { horas: horasAnteriores } = this.state

    if (horas !== horasAnteriores) {
      this.crearHorasTextuales(horas)
    }
    return null
  }

  crearHorasTextuales = (horas) => {
    let { keyButton } = this.state
    const { selectTime } = this.props

    const horasTextuales = horas.map((itemHora) => {
      const hora = moment(itemHora.time, 'YYYY-MM-DDTHH:mm:ssZ').toDate().getHours()
      const decenasMinutos = moment(itemHora.time, 'YYYY-MM-DDTHH:mm:ssZ').toDate().getMinutes() < 10 ? '0' : ''
      const unidadesMinutos = moment(itemHora.time, 'YYYY-MM-DDTHH:mm:ssZ').toDate().getMinutes()

      const texto = `${hora}:${decenasMinutos}${unidadesMinutos}`

      return { ...itemHora, texto }
    })

    if (horasTextuales.length > 0) {
      if (keyButton !== horasTextuales[0].texto) {
        keyButton = horasTextuales[0].texto
        selectTime(horasTextuales[0])
      }
    }

    this.setState({
      horas,
      horasTextuales,
      keyButton,
    })
  }

  selectTime = (hora) => {
    const { selectTime } = this.props

    selectTime(hora)

    this.setState({
      keyButton: hora.texto,
    })
  }

  render() {
    const { keyButton, horasTextuales } = this.state

    const horasDisponibles = horasTextuales.length > 0

    return (
      horasDisponibles
        ? (
          <div className={styles.horaContent}>
            {horasTextuales.map((itemHoras, index) => (
              <BotonHora
                hora={horasTextuales[index]}
                selectTime={this.selectTime}
                className={(keyButton === itemHoras.texto) ? stylesBoton.horaBtnElementActivo : stylesBoton.horaBtnElement}
                key={`key-${index * 2}`}
              />
            ))}
          </div>
        )
        : (
          <div className={styles.divMartin}>
            <img src={martinSinCitas} alt="" className={styles.martinSinHorario} />
          </div>
        )
    )
  }
}

TarjetasHora.propTypes = {
  horas: PropTypes.array,
  selectTime: PropTypes.func,
}

TarjetasHora.defaultProps = {
  horas: [],
  selectTime: () => {},
}
